export const headers = [
    'Event',
    'Ranking',
    'Date',
    'Venue',
    'Location',
    'Capacity',
    'Presale/Public Sale',
    'Ticket Limit & Price Range',
    'My Notes',
    'ACTION',
]
