import React, { useState, useEffect, useMemo, ChangeEvent } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    IconButton,
    ListItemText,
    Paper,
    Slide,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import 'react-calendar/dist/Calendar.css'
import { Helmet } from 'react-helmet-async'
import { ANALYTICS_COUNT_QUERY_KEY, ANALYTICS_DATA_QUERY_KEY, GET_USER_FILTERS } from 'src/common/constants/queryKeys'
import { AnalyticsFilters } from 'src/components/dashboard/eventAnalytics/AnalyticsFilters'
import { EventAnalyticsTable } from 'src/components/dashboard/eventAnalytics/AnalyticsTable'
import { EventModalGroup } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModalGroup'
import StyledPagination from 'src/components/dashboard/generalComponents/StyledPagination'
import { LoaderComponent } from 'src/components/dashboard/loaderComponent/loaderComp'
import { CustomSwitch } from 'src/components/general/CustomSwitch'
import useEventAnalytics from 'src/hooks/useEventAnalytics'
import { EventAPI } from 'src/services/eventAnalyticsAPI'
import numberWithCommas from 'src/utils/numberWithCommas'
import { platformSelectArray as platformSelectArrayDefault } from 'src/common/constants/overview'
import { StyledSelectWithBorder } from 'src/components/general/StyledSelect'
import { StyledMenuItem } from 'src/components/dashboard/generalComponents/TableComponents'
import { userApi } from 'src/services/userApi'
import dayjs from 'dayjs'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import useAuth from 'src/hooks/useAuth'
import { SearchBar } from 'src/components/general/SearchBar'

const platformSelectArray = [...platformSelectArrayDefault, { name: 'stubhub', tag: 'Stubhub W/O Primary' }]

export default function EventAnalyticsComponent() {
    const queryClient = useQueryClient()

    const {
        user: { _id },
    } = useAuth()

    const {
        dataCategories,
        popupText,
        filterState,
        firstSearchValueInput,
        secondSearchValueInput,
        selectedId,
        page,
        category,
        marketplace,
        seatGroups,
        perPage,
        totalPages,
        allEntries,
        modals,
        startDate,
        endDate,
        fromPresaleDate,
        toPresaleDate,
        sliderValue,
        selectedMeta,
        selectedEvent,
        switcher,
        selectedArtistId,
        artistsArray,
        setPopupText,
        setCategory,
        setMarketplace,
        seatType,
        setSeatType,
        seatGroupList,
        setSeatGroups,
        handleChangeModal,
        handleChangePage,
        handleChangePerPage,
        setSelectedId,
        setSelectedMeta,
        setSelectedSorting,
        handleChangeFirstSearchInput,
        handleChangeSecondSearchInput,
        handleChangeSlider,
        handleDisableAll,
        handleSetStartDate,
        handleSetEndDate,
        handleSetFromPresaleDate,
        handleSetToPresaleDate,
        setAllEntries,
        handleEnterKeyPress,
        selectedSorting,
        handleInputEnter,
        changeModal,
        isModalOpen,
        setFilterState,
        setSelectedArtist,
        setSelectedEvent,
        setSwitcher,
        setSliderValue,
        excluded,
        setExcluded,
        saleType,
        setSaleType,
    } = useEventAnalytics()
    const [hoveredAddButton, setHoveredAddButton] = useState<boolean>()
    const [hoverSaveFilterButton, setHoverSaveFilterButton] = useState<boolean>()

    const params = useMemo(() => {
        let categoryCopy = [...category]
        if (dataCategories?.data?.data.length === category.length) {
            categoryCopy = []
        }
        return {
            category: categoryCopy.join(),
            spotifyMin: sliderValue.spotifyRankValue[0],
            spotifyMax: sliderValue.spotifyRankValue[1],
            capacityMin: sliderValue.capacityValue[0],
            capacityMax: sliderValue.capacityValue[1],
            priceMin: sliderValue.priceValue[0],
            priceMax: sliderValue.priceValue[1],
            primaryMin: sliderValue.primaryValue[0],
            primaryMax: sliderValue.primaryValue[1],
            soldPercentageMin: sliderValue.sellouts[0],
            soldPercentageMax: sliderValue.sellouts[1],
            secondaryMin: sliderValue.secondaryValue[0],
            secondaryMax: sliderValue.secondaryValue[1],
            startDate: startDate === 'Invalid Date' ? '' : startDate,
            endDate: endDate === 'Invalid Date' ? '' : endDate,
            fromPresaleDate: fromPresaleDate === 'Invalid Date' ? '' : fromPresaleDate,
            toPresaleDate: toPresaleDate === 'Invalid Date' ? '' : toPresaleDate,
            platform: marketplace.length === platformSelectArray.length ? [] : marketplace,
            exclude: excluded.join(),
            seatType: seatType.join(),
            saleType: saleType,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switcher])

    const {
        data: EventAnalyticsData,
        isLoading: isLoadingData,
        isRefetching: isRefetchingData,
    } = useQuery({
        queryKey: [
            ANALYTICS_DATA_QUERY_KEY,
            page,
            perPage,
            firstSearchValueInput,
            secondSearchValueInput,
            selectedSorting,
            params,
        ],
        queryFn: () =>
            EventAPI.getEventAnalytics({
                perPage,
                page,
                filterState,
                search: firstSearchValueInput,
                secondSearch: secondSearchValueInput,
                ...params,
                sortBy: selectedSorting.split(' ')[0],
                sortOrder: selectedSorting.split(' ')[1],
                //seatType: seatType.join()
            }),
        initialData: null,
    })

    const {
        data: EventAnalyticsCount,
        isLoading: isLoadingCount,
        isRefetching: isRefetchingCount,
    } = useQuery({
        queryKey: [
            ANALYTICS_COUNT_QUERY_KEY,
            page,
            perPage,
            firstSearchValueInput,
            secondSearchValueInput,
            selectedSorting,
            params,
        ],
        queryFn: () =>
            EventAPI.getEventAnalyticsCount({
                perPage,
                page,
                filterState,
                search: firstSearchValueInput,
                secondSearch: secondSearchValueInput,
                ...params,
                sortBy: selectedSorting.split(' ')[0],
                sortOrder: selectedSorting.split(' ')[1],
                seatType: seatType.join(),
            }),
        initialData: null,
    })

    const [searchable, setSearchable] = useState('')
    const {
        data: searchTips,
        isLoading: isLoadingSearchTips,
        isPaused: isPausedSearchTips,
    } = useQuery({
        queryKey: [ANALYTICS_DATA_QUERY_KEY, 'searchTips', searchable],
        queryFn: () => EventAPI.getSearchTips(searchable),
        enabled: searchable.length >= 4,
        initialData: [],
    })

    const [eventAnalyticsData, setEventAnalyticsData] = useState(null)

    const [selectedFilter, setSelectedFilter] = useState(null)
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)
    const [newFilterName, setNewFilterName] = useState('')

    useEffect(() => {
        if (selectedFilter) {
            setSliderValue({
                spotifyRankValue: [selectedFilter.filter.spotifyMin, selectedFilter.filter.spotifyMax],
                capacityValue: [selectedFilter.filter.capacityMin, selectedFilter.filter.capacityMax],
                priceValue: [selectedFilter.filter.priceMin, selectedFilter.filter.priceMax],
                primaryValue: [selectedFilter.filter.primaryMin, selectedFilter.filter.primaryMax],
                secondaryValue: [selectedFilter.filter.secondaryMin, selectedFilter.filter.secondaryMax],
                sellouts: [selectedFilter.filter.soldPercentageMin, selectedFilter.filter.soldPercentageMax],
            })
            handleSetStartDate(
                selectedFilter.filter.startDate ? dayjs(selectedFilter.filter.startDate, 'YYYY-MM-DD').toDate() : null,
                null
            )
            handleSetEndDate(
                selectedFilter.filter.endDate ? dayjs(selectedFilter.filter.endDate, 'YYYY-MM-DD').toDate() : null,
                null
            )
            if (selectedFilter.filter.category.length) {
                setCategory(selectedFilter.filter.category.split(','))
            }
            setMarketplace(selectedFilter.filter.platform)
            handleChangeFirstSearchInput(selectedFilter.filter.firstSearchValueInput)
            handleChangeSecondSearchInput(selectedFilter.filter.secondSearchValueInput)
            setExcluded(selectedFilter.filter.exclude ? selectedFilter.filter.exclude.split(',') : [])
            setSeatType(selectedFilter.filter.seatType ? selectedFilter.filter.seatType.split(',') : [])
        } else {
            handleDisableAll()
        }
        setSwitcher((e) => !e)
    }, [selectedFilter])

    const [userSavedFilters, setUserSavedFilters] = useState([])

    const { data: savedFiltersResponse } = useQuery({
        queryKey: [GET_USER_FILTERS, ANALYTICS_DATA_QUERY_KEY],
        queryFn: () => userApi.getSavedFilters(ANALYTICS_DATA_QUERY_KEY),
    })

    useEffect(() => {
        if (savedFiltersResponse) {
            setUserSavedFilters(savedFiltersResponse.data)
        }
    }, [savedFiltersResponse])

    const handleOpenSaveFilterModal = () => {
        setNewFilterName('')
        setIsModalEditOpen(true)
    }

    const { mutate: handleSaveFilter } = useMutation({
        mutationFn: () =>
            userApi.saveFilter(ANALYTICS_DATA_QUERY_KEY, newFilterName, {
                ...params,
                firstSearchValueInput,
                secondSearchValueInput,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_USER_FILTERS, ANALYTICS_DATA_QUERY_KEY] })
            setIsModalEditOpen(false)
        },
    })

    const { mutate: handleRemoveFilter } = useMutation({
        mutationFn: (filterId: string) => userApi.removeFilter(filterId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_USER_FILTERS, ANALYTICS_DATA_QUERY_KEY] })
        },
    })

    const handleSetEventAsViewed = async (eventId: string, viewed: boolean) => {
        await EventAPI.setEventFromAnalyticsViewed(eventId, viewed)
        setEventAnalyticsData((prevValue) => {
            return prevValue.map((item) => {
                if (item._id !== eventId) return item
                return {
                    ...item,
                    analytics_notes: viewed ? [{ viewed }] : [],
                }
            })
        })
    }

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
    const [filterToDelete, setFilterToDelete] = useState(null)

    const handleOpenDeleteConfirm = (filterId) => {
        setFilterToDelete(filterId)
        setIsDeleteConfirmOpen(true)
    }

    const handleConfirmDeleteFilter = () => {
        handleRemoveFilter(filterToDelete)
        setIsDeleteConfirmOpen(false)
    }

    useEffect(() => {
        handleChangePage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, firstSearchValueInput, selectedSorting, startDate, endDate, sliderValue, filterState, category])

    const onChangeFilterState = (e: ChangeEvent<HTMLInputElement>) => {
        setFilterState(e.target.checked)
    }

    const [isLoaded, setIsIsLoaded] = useState(false)

    useEffect(() => {
        if (!isLoadingData && EventAnalyticsData) {
            setEventAnalyticsData(EventAnalyticsData?.data?.data)
        }
        if (!isLoadingCount && EventAnalyticsCount) {
            setAllEntries(EventAnalyticsCount.data.total_events)
        }
        if (!isLoadingData && EventAnalyticsData && !isLoadingCount && EventAnalyticsCount) {
            setIsIsLoaded(true)
        }
    }, [EventAnalyticsData, setEventAnalyticsData, isLoadingData, EventAnalyticsCount, setAllEntries, isLoadingCount])

    return (
        <>
            <Helmet>
                <title>Event Analytics | Ticketmetric</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    py: '25px',
                    px: '12px',
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#F7F6F4',
                }}
                onKeyDown={handleEnterKeyPress}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        py: '57px',
                        px: '51px',
                        height: '100%',
                        boxShadow: 0,
                        borderRadius: 0,
                        mb: '9px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            width: '100%',
                        }}
                    >
                        <Typography sx={{ textWrap: 'nowrap', mr: '24px' }} variant="h5">
                            Event Analytics
                        </Typography>
                        <Stack
                            sx={{
                                width: '280px',
                                mr: '16px',
                                gap: 1,
                            }}
                        >
                            <Stack
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{
                                    borderRadius: '8px',
                                    padding: '4px 12px',
                                    border: 'solid',
                                    borderWidth: '1px',
                                    borderColor: '#E9D7FE',
                                    height: '40px',
                                }}
                            >
                                <Typography sx={{ color: '#AE763F' }} noWrap={true}>
                                    {numberWithCommas(allEntries)} events
                                </Typography>
                            </Stack>
                            {/* <StyledSelectWithBorder
                                placeholder="Sort By"
                                onChange={(e) => setSelectedSorting(e.target.value as string)}
                                sx={{
                                    height: '40px',
                                    '.MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                }}
                                value={selectedSorting}
                            >
                                <StyledMenuItem
                                    value={'dateTime asc'}
                                    sx={{ padding: '0px 0px 3px 2px', color: 'black' }}
                                >
                                    <ListItemText primary={'Sort by Date'} />
                                    <ArrowUpwardIcon />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    value={'dateTime desc'}
                                    sx={{ padding: '0px 0px 3px 2px', color: 'black' }}
                                >
                                    <ListItemText primary={'Sort by Date'} />
                                    <ArrowDownwardIcon />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    value={'meta.otherPlatforms.totalPlaces asc'}
                                    sx={{ padding: '0px 0px 3px 2px', color: 'black' }}
                                >
                                    <ListItemText primary={'Sort by Capacity'} />
                                    <ArrowUpwardIcon />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    value={'meta.otherPlatforms.totalPlaces desc'}
                                    sx={{ padding: '0px 0px 3px 2px', color: 'black' }}
                                >
                                    <ListItemText primary={'Sort by Capacity'} />
                                    <ArrowDownwardIcon />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    value={'popularity asc'}
                                    sx={{ padding: '0px 0px 3px 2px', color: 'black' }}
                                >
                                    <ListItemText primary={'Sort by Popularity'} />
                                    <ArrowUpwardIcon />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    value={'popularity desc'}
                                    sx={{ padding: '0px 0px 3px 2px', color: 'black' }}
                                >
                                    <ListItemText primary={'Sort by Popularity'} />
                                    <ArrowDownwardIcon />
                                </StyledMenuItem>
                            </StyledSelectWithBorder>*/}
                        </Stack>
                        <Stack
                            sx={{
                                width: '100%',
                                mr: '16px',
                                gap: 1,
                            }}
                        >
                            <SearchBar
                                disableDebounce
                                value={firstSearchValueInput}
                                onSearch={handleChangeFirstSearchInput}
                                onInfoClick={() => changeModal('firstInfoModal')}
                                onKeyDown={handleInputEnter}
                                placeholder="Search by URL or Name"
                                handleSearchableChange={(v) => {
                                    setSearchable(v)
                                }}
                                suggestions={!isLoadingSearchTips && !isPausedSearchTips ? searchTips : []}
                            />
                            <SearchBar
                                disableDebounce
                                value={secondSearchValueInput}
                                onSearch={handleChangeSecondSearchInput}
                                onInfoClick={() => changeModal('secondInfoModal')}
                                onKeyDown={handleInputEnter}
                                placeholder="And Add a Second Filter by Venue or City"
                            />
                        </Stack>
                        <Stack gap={1}>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Box
                                    sx={{
                                        border: '1px solid',
                                        borderColor: '#D0D5DD',
                                        borderRadius: '8px',
                                        p: '9px 7.5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        mr: '18px',
                                    }}
                                >
                                    <FilterListIcon fontSize="small" />
                                    <Typography
                                        sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '20px' }}
                                        noWrap={true}
                                    >
                                        Show Filters
                                    </Typography>
                                    <CustomSwitch
                                        checked={filterState}
                                        onChange={onChangeFilterState}
                                        checkedcolor="#204391"
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        sx={{
                                            border: '1px solid',
                                            borderColor: '#D0D5DD',
                                            borderRadius: '8px',
                                            width: '43px',
                                            height: '40px',
                                            minWidth: '43px',
                                        }}
                                        onClick={() => changeModal('addModal')}
                                        onMouseEnter={() => setHoveredAddButton(true)}
                                        onMouseLeave={() => setHoveredAddButton(false)}
                                    >
                                        <Typography sx={{ fontSize: '24px', fontWeight: '600', color: '#E4933D' }}>
                                            +
                                        </Typography>
                                    </Button>
                                    <Box
                                        sx={{
                                            display: hoveredAddButton ? 'block' : 'none',
                                            position: 'absolute',
                                            bgcolor: 'rgba(30,30,30,.8)',
                                            zIndex: '1600',
                                            p: '10px',
                                            right: '95px',
                                            borderRadius: '4px',
                                            color: 'white',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Add a Missing Event
                                    </Box>
                                </Box>
                            </Stack>
                            {filterState && (
                                <Stack direction={'row'} alignItems={'center'}>
                                    <FormControl
                                        sx={{
                                            minWidth: '141px',
                                            borderRadius: '8px',
                                            height: '40px',
                                            width: '-webkit-fill-available',
                                        }}
                                    >
                                        <StyledSelectWithBorder
                                            placeholder="Saved filters"
                                            onChange={(e) => setSelectedFilter(e.target.value)}
                                            sx={{
                                                height: '40px',
                                                mr: '18px',
                                                display: userSavedFilters.length ? 'block' : 'none',
                                            }}
                                            renderValue={({ name }) => `${name}`}
                                        >
                                            <StyledMenuItem
                                                value={null}
                                                sx={{ padding: '0px 0px 3px 2px', color: '#ccc' }}
                                            >
                                                <ListItemText primary={'Off'} />
                                            </StyledMenuItem>
                                            {userSavedFilters.map((item, index) => (
                                                <StyledMenuItem
                                                    key={index}
                                                    value={item}
                                                    sx={{
                                                        padding: '0px 0px 3px 2px',
                                                        color: item.user_id === _id ? 'unset' : 'blue',
                                                    }}
                                                >
                                                    <ListItemText primary={item.name} />
                                                    {/* <IconButton sx={{ padding: 0 }}>
                                                        <EditIcon />
                                                    </IconButton> */}
                                                    {item.user_id === _id && (
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                handleOpenDeleteConfirm(item._id)
                                                            }}
                                                            sx={{ padding: 0 }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </StyledMenuItem>
                                            ))}
                                        </StyledSelectWithBorder>
                                    </FormControl>
                                    <Box>
                                        <Button
                                            sx={{
                                                border: '1px solid',
                                                borderColor: '#D0D5DD',
                                                borderRadius: '8px',
                                                width: '43px',
                                                height: '40px',
                                                minWidth: '43px',
                                            }}
                                            onClick={handleOpenSaveFilterModal}
                                            onMouseEnter={() => setHoverSaveFilterButton(true)}
                                            onMouseLeave={() => setHoverSaveFilterButton(false)}
                                        >
                                            <SaveIcon sx={{ fontSize: '24px', color: '#E4933D' }} />
                                        </Button>
                                        <Box
                                            sx={{
                                                display: hoverSaveFilterButton ? 'block' : 'none',
                                                position: 'absolute',
                                                bgcolor: 'rgba(30,30,30,.8)',
                                                zIndex: '1600',
                                                p: '10px',
                                                right: '95px',
                                                borderRadius: '4px',
                                                color: 'white',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                            }}
                                        >
                                            Save your current filter, please make sure you press <b>Apply</b> before
                                        </Box>
                                    </Box>
                                </Stack>
                            )}
                        </Stack>
                    </Box>

                    {filterState && (
                        <AnalyticsFilters
                            setSwitcher={setSwitcher}
                            setCategory={setCategory}
                            setMarketplace={setMarketplace}
                            marketplace={marketplace}
                            seatType={seatType}
                            setSeatType={setSeatType}
                            seatGroupList={seatGroupList}
                            setSeatGroups={setSeatGroups}
                            seatGroups={seatGroups}
                            category={category}
                            dataCategories={dataCategories}
                            startDate={startDate as Date}
                            endDate={endDate as Date}
                            fromPresaleDate={fromPresaleDate as Date}
                            toPresaleDate={toPresaleDate as Date}
                            handleDisableAll={handleDisableAll}
                            handleSetEndDate={handleSetEndDate}
                            handleSetStartDate={handleSetStartDate}
                            handleSetFromPresaleDate={handleSetFromPresaleDate}
                            handleSetToPresaleDate={handleSetToPresaleDate}
                            handleChangeSlider={handleChangeSlider}
                            sliderValue={sliderValue}
                            excluded={excluded}
                            setExcluded={setExcluded}
                            saleType={saleType}
                            setSaleType={setSaleType}
                        />
                    )}
                </Paper>
                {!isLoaded || isRefetchingData || isRefetchingCount ? (
                    <LoaderComponent />
                ) : allEntries > 0 ? (
                    <Box sx={{ width: '100%' }}>
                        {eventAnalyticsData && (
                            <EventAnalyticsTable
                                setSelectedMeta={setSelectedMeta}
                                setSelectedSorting={setSelectedSorting}
                                setSelectedId={setSelectedId}
                                setSelectedArtist={setSelectedArtist}
                                changeModal={changeModal}
                                setSelectedEvent={setSelectedEvent}
                                data={eventAnalyticsData}
                                setData={setEventAnalyticsData}
                                handleSetEventAsViewed={handleSetEventAsViewed}
                            />
                        )}
                        <StyledPagination
                            count={totalPages}
                            setPage={handleChangePage}
                            page={page}
                            perPage={perPage}
                            setPerPage={handleChangePerPage}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            width: '100%',
                            py: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography sx={{ fontSize: '24px' }}>No records found</Typography>
                    </Box>
                )}
            </Box>
            <EventModalGroup
                handleChangeModal={handleChangeModal}
                changeModal={changeModal}
                isModalOpen={isModalOpen}
                eventArtists={artistsArray}
                selectedId={selectedId}
                selectedMeta={selectedMeta}
                setPopupText={setPopupText}
                selectedEvent={selectedEvent}
                selectedArtistId={selectedArtistId}
                pageType=""
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={popupText !== ''}
                autoHideDuration={3000}
                TransitionComponent={Slide}
                onClose={() => {
                    setPopupText('')
                }}
                sx={{ '& .MuiSnackbarContent-root': { minWidth: '100px' } }}
                message={popupText}
            />
            <Dialog open={isModalEditOpen} onClose={() => setIsModalEditOpen(false)}>
                <DialogContent>
                    <TextField
                        value={newFilterName}
                        onChange={(e) => setNewFilterName(e.target.value)}
                        placeholder="Name your filter"
                        sx={{ width: '100%', mb: '20px' }}
                    />
                    <Button
                        onClick={() => handleSaveFilter()}
                        sx={{
                            width: '100%',
                            backgroundColor: '#204391',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#204391',
                            },
                        }}
                    >
                        Save
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog open={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)}>
                <DialogContent>
                    <Typography>Are you sure you want to delete this filter?</Typography>
                    <Stack direction="row" justifyContent="space-between" mt={2}>
                        <Button
                            onClick={() => setIsDeleteConfirmOpen(false)}
                            sx={{
                                backgroundColor: '#E0E0E0',
                                color: '#000',
                                '&:hover': {
                                    backgroundColor: '#D0D0D0',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeleteFilter}
                            sx={{
                                backgroundColor: '#D32F2F',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#C62828',
                                },
                            }}
                        >
                            Delete
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
