import { Box, Button, Typography } from '@mui/material'

export const GetInPriceInfoModal = ({ changeModal }) => {
    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '550px',
                gap: '20px',
                zIndex: '2500',
            }}
        >
            <Box>
                <Box
                    sx={{
                        boxShadow: '0px 2px 11px -2px rgba(0,0,0,0.1);',
                        p: '10px',
                        mt: '6px',
                        bgcolor: '#ECF0F1',
                        borderRadius: '10px',
                    }}
                >
                    <Typography sx={{ mx: 'auto', fontSize: '15px', textAlign: 'start' }}>
                        <b>First Line:</b> Displays the cheapest single ticket price on StubHub.
                    </Typography>
                    <Typography sx={{ mx: 'auto', fontSize: '15px', textAlign: 'start' }}>
                        <b>Second Line:</b> Displays the cheapest price for a pair or more of tickets.
                    </Typography>
                </Box>
            </Box>

            <Button
                type="submit"
                onClick={() => {
                    changeModal('getInPriceInfoModal')
                }}
                sx={{ width: '80px', bgcolor: 'black', ':hover': { bgcolor: 'black' } }}
            >
                Close
            </Button>
        </Box>
    )
}
