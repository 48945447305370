import dayjs from 'dayjs'
import {
    ApiAddNoteItemRequest,
    ApiDeleteDropCheckRequest,
    ApiDeleteLowInventoryAlertRequest,
    ApiDeleteNoteItemRequest,
    ApiGetEventsNotesRequest,
    ApiGetEventsNotesResponse,
    ApiGetFavoritesRequest,
    ApiGetSecondaryTopSelling,
} from 'src/types/api/apiEventAnalyticsTypes'
import axiosClient from 'src/utils/axios'
import {
    LocalAddLowInventoryAlertRequest,
    LocalUpdateLowInventoryAlertRequest,
} from './../types/local/localEventAnalyticsTypes'
import { IPrimaryFavoriteResponse } from 'src/types/local/primaryFavorite'
import { defaultSliderValue as eventAnalyticsDefaultSliderValue } from 'src/hooks/useEventAnalytics'
import { defaultSliderValue as topMoversDefaultSliderValue } from 'src/hooks/useTopMovers'
import { defaultSliderValue as topMoversSecondaryDefaultSliderValue } from 'src/hooks/useTopMoversSecondary'

import { FrontEndError } from 'src/utils/error'

class EventAnalyticsAPI {
    async getEventsScrapSectionInformation({ eventId }: { eventId: string }) {
        return axiosClient().get(`/api/events/scrap-section-information/${eventId}`)
    }

    async getEventsScrapSectionInformationStubhub({ eventId }: { eventId: string }) {
        return axiosClient().get(`/api/events/scrap-section-information-stubhub/${eventId}`)
    }

    // Adapt this function for react-query queryFn
    async getEventsNotes({ page, perPage }: ApiGetEventsNotesRequest): Promise<ApiGetEventsNotesResponse> {
        const response = await axiosClient().get(`/api/events/get-drop-check-alerts?page=${page}&per_page=${perPage}`)
        return response.data
    }

    async getPrimaryFavorites({
        page,
        perPage,
        name,
        category,
        includeTeam,
        sortField,
        sortOrder,
    }: ApiGetFavoritesRequest): Promise<IPrimaryFavoriteResponse> {
        const response = await axiosClient().get(`/api/user/events/favorite/primary`, {
            params: {
                page,
                per_page: perPage,
                name,
                ...(category.length ? { category: category.join(',') } : {}),
                include_team: includeTeam,
                sort_by: sortField,
                sort_order: sortOrder,
            },
        })
        return response.data
    }

    async getSecondaryFavorites({
        page,
        perPage,
        name,
        category,
        includeTeam,
        sortField,
        sortOrder,
    }: ApiGetFavoritesRequest): Promise<IPrimaryFavoriteResponse> {
        const response = await axiosClient().get(`/api/user/events/favorite/secondary`, {
            params: {
                page,
                per_page: perPage,
                name,
                category,
                include_team: includeTeam,
                sort_by: sortField,
                sort_order: sortOrder,
            },
        })
        return response.data
    }

    async getEvent({ eventId }: { eventId: string }) {
        return await axiosClient().get(`/api/events/${eventId}`)
    }

    async getSectionInfo({ eventId }: { eventId: string }) {
        return axiosClient().get(`/api/events/get-section-info/${eventId}`)
    }

    async deleteLowInventoryAlert({ alertId }: ApiDeleteLowInventoryAlertRequest) {
        return axiosClient().delete(`/api/events/remove-low-inventory-alert/${alertId}`)
    }

    async addDropCheckAlert({ eventId, ticket_types, quantity, areas, price_range_start, price_range_end }: any) {
        return axiosClient().post('/api/events/add-drop-checker-alert', {
            event_id: eventId,
            ticket_types,
            quantity,
            areas,
            price_range_start,
            price_range_end,
        })
    }

    async addLowInventoryAlert({ eventId, sections_to_track, ticket_count }: LocalAddLowInventoryAlertRequest) {
        return axiosClient().post('/api/events/add-low-inventory-alert', {
            event_id: eventId,
            sections_to_track,
            ticket_count,
        })
    }

    async updateLowInventoryAlert({ alertId, sections_to_track, ticket_count }: LocalUpdateLowInventoryAlertRequest) {
        return axiosClient().post('/api/events/update-low-inventory-alert', {
            alert_id: alertId,
            sections_to_track,
            ticket_count,
        })
    }

    async deleteNoteItem({ eventId }: ApiDeleteNoteItemRequest) {
        return await axiosClient().delete(`/api/events/remove-note/${eventId}`)
    }

    async deleteDropCheck({ eventId }: ApiDeleteDropCheckRequest) {
        return await axiosClient().delete(`/api/events/remove-drop-check-alert/${eventId}`)
    }

    async addNoteItem({ eventId, note, isSecondary }: ApiAddNoteItemRequest) {
        return await axiosClient().post('/api/events/notes/save', {
            text: note,
            event_id: eventId,
            type: isSecondary ? 'my_secondary' : 'my_primary',
        })
    }

    async getSections(eventId: string) {
        return axiosClient().get(`/api/events/get-sections/${eventId}`)
    }

    private getEventAnalyticsParams({
        perPage,
        page,
        sortBy,
        sortOrder,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        primaryMin,
        primaryMax,
        soldPercentageMin,
        soldPercentageMax,
        secondaryMin,
        secondaryMax,
        startDate,
        endDate,
        fromPresaleDate,
        toPresaleDate,
        search,
        secondSearch,
        category,
        filterState,
        platform,
        exclude,
        saleType,
    }) {
        let params: any = {
            per_page: perPage,
            page: page,
            section_info: true,
            sort_by: sortBy,
            sort_order: sortOrder,
            spotifyMin,
            spotifyMax,
            category,
            capacityMin,
            capacityMax,
            priceMin,
            priceMax,
            primaryMin,
            primaryMax,
            soldPercentageMin,
            soldPercentageMax,
            secondaryMin,
            secondaryMax,
            startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
            endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
            fromPresaleDate: fromPresaleDate ? dayjs(fromPresaleDate).format('YYYY-MM-DD') : '',
            toPresaleDate: toPresaleDate ? dayjs(toPresaleDate).format('YYYY-MM-DD') : '',
            search,
            secondSearch,
            platform: platform.join(','),
            exclude,
            saleType,
        }

        if (
            spotifyMax === eventAnalyticsDefaultSliderValue.spotifyRankValue[1] &&
            spotifyMin === eventAnalyticsDefaultSliderValue.spotifyRankValue[0]
        ) {
            delete params.spotifyMax
            delete params.spotifyMin
        }
        if (
            priceMax === eventAnalyticsDefaultSliderValue.priceValue[1] &&
            priceMin === eventAnalyticsDefaultSliderValue.priceValue[0]
        ) {
            delete params.priceMax
            delete params.priceMin
        }
        if (
            capacityMax === eventAnalyticsDefaultSliderValue.capacityValue[1] &&
            capacityMin === eventAnalyticsDefaultSliderValue.capacityValue[0]
        ) {
            delete params.capacityMax
            delete params.capacityMin
        }
        if (
            primaryMax === eventAnalyticsDefaultSliderValue.primaryValue[1] &&
            primaryMin === eventAnalyticsDefaultSliderValue.primaryValue[0]
        ) {
            delete params.primaryMax
            delete params.primaryMin
        }
        if (
            secondaryMax === eventAnalyticsDefaultSliderValue.secondaryValue[1] &&
            secondaryMin === eventAnalyticsDefaultSliderValue.secondaryValue[0]
        ) {
            delete params.secondaryMax
            delete params.secondaryMin
        }
        if (
            soldPercentageMax === eventAnalyticsDefaultSliderValue.sellouts[1] &&
            soldPercentageMin === eventAnalyticsDefaultSliderValue.sellouts[0]
        ) {
            delete params.soldPercentageMax
            delete params.soldPercentageMin
        }

        // Filter out empty values
        params = Object.fromEntries(Object.entries(params)?.filter(([_, value]) => value !== ''))

        return params
    }

    async getEventAnalytics({
        perPage,
        page,
        sortBy,
        sortOrder,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        primaryMin,
        primaryMax,
        soldPercentageMin,
        soldPercentageMax,
        secondaryMin,
        secondaryMax,
        startDate,
        endDate,
        fromPresaleDate,
        toPresaleDate,
        search,
        secondSearch,
        category,
        filterState,
        platform,
        exclude,
        seatType,
        saleType,
    }) {
        try {
            const params = this.getEventAnalyticsParams({
                perPage,
                page,
                sortBy,
                sortOrder,
                spotifyMin,
                spotifyMax,
                capacityMin,
                capacityMax,
                priceMin,
                priceMax,
                primaryMin,
                primaryMax,
                soldPercentageMin,
                soldPercentageMax,
                secondaryMin,
                secondaryMax,
                startDate,
                endDate,
                fromPresaleDate,
                toPresaleDate,
                search,
                secondSearch,
                category,
                filterState,
                platform,
                exclude,
                saleType,
            })

            const bodyParams = {
                seatType,
            }

            return await axiosClient().post(
                `/api/analytics/all`,
                { ...bodyParams },
                {
                    params: params,
                }
            )
        } catch (err) {
            FrontEndError(err)
        }
    }

    async getEventAnalyticsCount({
        perPage,
        page,
        sortBy,
        sortOrder,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        primaryMin,
        primaryMax,
        soldPercentageMin,
        soldPercentageMax,
        secondaryMin,
        secondaryMax,
        startDate,
        endDate,
        fromPresaleDate,
        toPresaleDate,
        search,
        secondSearch,
        category,
        filterState,
        platform,
        exclude,
        seatType,
        saleType,
    }) {
        try {
            const params = this.getEventAnalyticsParams({
                perPage,
                page,
                sortBy,
                sortOrder,
                spotifyMin,
                spotifyMax,
                capacityMin,
                capacityMax,
                priceMin,
                priceMax,
                primaryMin,
                primaryMax,
                soldPercentageMin,
                soldPercentageMax,
                secondaryMin,
                secondaryMax,
                startDate,
                endDate,
                fromPresaleDate,
                toPresaleDate,
                search,
                secondSearch,
                category,
                filterState,
                platform,
                exclude,
                saleType,
            })

            const bodyParams = {
                seatType,
            }

            return await axiosClient().post(
                `/api/analytics/all-count`,
                { ...bodyParams },
                {
                    params: params,
                }
            )
        } catch (err) {
            FrontEndError(err)
        }
    }

    async getPrimaryTopMovers({
        perPage,
        page,
        sortBy,
        sortOrder,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        primaryMin,
        primaryMax,
        soldPercentageMin,
        soldPercentageMax,
        secondaryMin,
        secondaryMax,
        startDate,
        endDate,
        fromPresaleDate,
        toPresaleDate,
        search,
        secondSearch,
        category,
        filterState,
        platform,
        exclude,
        ticketsCountDiffMin,
        ticketsCountDiffMax,
        ticketsPercentDiffMin,
        ticketsPercentDiffMax,
        movement,
    }) {
        try {
            let params: any = {
                per_page: perPage,
                page: page,
                section_info: true,
                sort_by: sortBy,
                sort_order: sortOrder,
                spotifyMin,
                spotifyMax,
                category,
                capacityMin,
                capacityMax,
                priceMin,
                priceMax,
                primaryMin,
                primaryMax,
                soldPercentageMin,
                soldPercentageMax,
                secondaryMin,
                secondaryMax,
                startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
                endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
                fromPresaleDate: fromPresaleDate ? dayjs(fromPresaleDate).format('YYYY-MM-DD') : '',
                toPresaleDate: toPresaleDate ? dayjs(toPresaleDate).format('YYYY-MM-DD') : '',
                search,
                secondSearch,
                platform: platform.join(','),
                exclude,
                ticketsCountDiffMin,
                ticketsCountDiffMax,
                ticketsPercentDiffMin,
                ticketsPercentDiffMax,
            }

            if (
                spotifyMax === topMoversDefaultSliderValue.spotifyRankValue[1] &&
                spotifyMin === topMoversDefaultSliderValue.spotifyRankValue[0]
            ) {
                delete params.spotifyMax
                delete params.spotifyMin
            }
            if (
                priceMax === topMoversDefaultSliderValue.priceValue[1] &&
                priceMin === topMoversDefaultSliderValue.priceValue[0]
            ) {
                delete params.priceMax
                delete params.priceMin
            }
            if (
                capacityMax === topMoversDefaultSliderValue.capacityValue[1] &&
                capacityMin === topMoversDefaultSliderValue.capacityValue[0]
            ) {
                delete params.capacityMax
                delete params.capacityMin
            }
            if (
                primaryMax === topMoversDefaultSliderValue.primaryValue[1] &&
                primaryMin === topMoversDefaultSliderValue.primaryValue[0]
            ) {
                delete params.primaryMax
                delete params.primaryMin
            }
            if (
                secondaryMax === topMoversDefaultSliderValue.secondaryValue[1] &&
                secondaryMin === topMoversDefaultSliderValue.secondaryValue[0]
            ) {
                delete params.secondaryMax
                delete params.secondaryMin
            }
            if (
                soldPercentageMax === topMoversDefaultSliderValue.sellouts[1] &&
                soldPercentageMin === topMoversDefaultSliderValue.sellouts[0]
            ) {
                delete params.soldPercentageMax
                delete params.soldPercentageMin
            }
            if (
                ticketsCountDiffMax === topMoversDefaultSliderValue.numberChange[1] &&
                ticketsCountDiffMin === topMoversDefaultSliderValue.numberChange[0]
            ) {
                delete params.ticketsCountDiffMin
                delete params.ticketsCountDiffMax
            }
            if (
                ticketsPercentDiffMax === topMoversDefaultSliderValue.percentChange[1] &&
                ticketsPercentDiffMin === topMoversDefaultSliderValue.percentChange[0]
            ) {
                delete params.ticketsPercentDiffMin
                delete params.ticketsPercentDiffMax
            }

            // Filter out empty values
            params = Object.fromEntries(Object.entries(params)?.filter(([_, value]) => value !== ''))

            return await axiosClient().get(`/api/analytics/top-movers?platform_type=primary&movers=${movement}`, {
                params,
            })
        } catch (err) {
            FrontEndError(err)
        }
    }

    async getSecondaryTopMovers({
        perPage,
        page,
        sortBy,
        sortOrder,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        primaryMin,
        primaryMax,
        soldPercentageMin,
        soldPercentageMax,
        secondaryMin,
        secondaryMax,
        startDate,
        endDate,
        fromPresaleDate,
        toPresaleDate,
        search,
        secondSearch,
        category,
        filterState,
        platform,
        exclude,
        secondaryTicketsCountDiffMin,
        secondaryTicketsCountDiffMax,
        secondaryTicketsPercentDiffMin,
        secondaryTicketsPercentDiffMax,

        ticketsPercentMinDiffMin,
        ticketsPercentMinDiffMax,
        ticketsMinPriceMin,
        ticketsMinPriceMax,

        movement,
    }) {
        try {
            let params: any = {
                per_page: perPage,
                page: page,
                section_info: true,
                sort_by: sortBy,
                sort_order: sortOrder,
                spotifyMin,
                spotifyMax,
                category,
                capacityMin,
                capacityMax,
                priceMin,
                priceMax,
                primaryMin,
                primaryMax,
                soldPercentageMin,
                soldPercentageMax,
                secondaryMin,
                secondaryMax,
                startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
                endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
                fromPresaleDate: fromPresaleDate ? dayjs(fromPresaleDate).format('YYYY-MM-DD') : '',
                toPresaleDate: toPresaleDate ? dayjs(toPresaleDate).format('YYYY-MM-DD') : '',
                search,
                secondSearch,
                platform: platform.join(','),
                exclude,
                secondaryTicketsCountDiffMin,
                secondaryTicketsCountDiffMax,
                secondaryTicketsPercentDiffMin,
                secondaryTicketsPercentDiffMax,

                ticketsPercentMinDiffMin,
                ticketsPercentMinDiffMax,
                ticketsMinPriceMin,
                ticketsMinPriceMax,
            }

            if (
                spotifyMax === topMoversSecondaryDefaultSliderValue.spotifyRankValue[1] &&
                spotifyMin === topMoversSecondaryDefaultSliderValue.spotifyRankValue[0]
            ) {
                delete params.spotifyMax
                delete params.spotifyMin
            }
            if (
                priceMax === topMoversSecondaryDefaultSliderValue.priceValue[1] &&
                priceMin === topMoversSecondaryDefaultSliderValue.priceValue[0]
            ) {
                delete params.priceMax
                delete params.priceMin
            }
            if (
                capacityMax === topMoversSecondaryDefaultSliderValue.capacityValue[1] &&
                capacityMin === topMoversSecondaryDefaultSliderValue.capacityValue[0]
            ) {
                delete params.capacityMax
                delete params.capacityMin
            }
            if (
                primaryMax === topMoversSecondaryDefaultSliderValue.primaryValue[1] &&
                primaryMin === topMoversSecondaryDefaultSliderValue.primaryValue[0]
            ) {
                delete params.primaryMax
                delete params.primaryMin
            }
            if (
                secondaryMax === topMoversSecondaryDefaultSliderValue.secondaryValue[1] &&
                secondaryMin === topMoversSecondaryDefaultSliderValue.secondaryValue[0]
            ) {
                delete params.secondaryMax
                delete params.secondaryMin
            }
            if (
                soldPercentageMax === topMoversSecondaryDefaultSliderValue.sellouts[1] &&
                soldPercentageMin === topMoversSecondaryDefaultSliderValue.sellouts[0]
            ) {
                delete params.soldPercentageMax
                delete params.soldPercentageMin
            }
            if (
                secondaryTicketsCountDiffMax === topMoversSecondaryDefaultSliderValue.numberChange[1] &&
                secondaryTicketsCountDiffMin === topMoversSecondaryDefaultSliderValue.numberChange[0]
            ) {
                delete params.secondaryTicketsCountDiffMin
                delete params.secondaryTicketsCountDiffMax
            }
            if (
                secondaryTicketsPercentDiffMax === topMoversSecondaryDefaultSliderValue.percentChange[1] &&
                secondaryTicketsPercentDiffMin === topMoversSecondaryDefaultSliderValue.percentChange[0]
            ) {
                delete params.secondaryTicketsPercentDiffMin
                delete params.secondaryTicketsPercentDiffMax
            }

            if (
                ticketsPercentMinDiffMax === topMoversSecondaryDefaultSliderValue.minPercentChange[1] &&
                ticketsPercentMinDiffMin === topMoversSecondaryDefaultSliderValue.minPercentChange[0]
            ) {
                delete params.ticketsPercentMinDiffMin
                delete params.ticketsPercentMinDiffMax
            }

            if (
                ticketsMinPriceMax === topMoversSecondaryDefaultSliderValue.minNumberChange[1] &&
                ticketsMinPriceMin === topMoversSecondaryDefaultSliderValue.minNumberChange[0]
            ) {
                delete params.ticketsMinPriceMin
                delete params.ticketsMinPriceMax
            }

            // Filter out empty values
            params = Object.fromEntries(Object.entries(params)?.filter(([_, value]) => value !== ''))

            return await axiosClient().get(
                `/api/analytics/secondary-top-movers?platform_type=secondary&movers=${movement}`,
                {
                    params,
                }
            )
        } catch (err) {
            FrontEndError(err)
        }
    }

    async setEventTopMoverAnalyzed(eventId: string, movers: string, status: string) {
        try {
            const url = `/api/user/event-top-mover-notes/${eventId}`
            await axiosClient().post(url, { status, movers })
        } catch (err) {
            FrontEndError(err)
        }
    }

    async getSecondaryTopSelling({ page, perPage, startDate, endDate }: ApiGetSecondaryTopSelling) {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD')
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD')

        const response = await axiosClient().get(
            `/api/analytics/top-selling?page=${page}&per_page=${perPage}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
        )
        return response.data
    }

    async getSearchTips(search: string) {
        try {
            const response = await axiosClient().get(`/api/analytics/search-tips?search=${search}`)
            return response.data
        } catch (err) {
            FrontEndError(err)
        }
    }

    async setEventFromAnalyticsViewed(eventId: string, viewed: boolean) {
        try {
            const url = `/api/user/event-analytics-notes/${eventId}`
            await axiosClient().post(url, { viewed })
        } catch (err) {
            FrontEndError(err)
        }
    }
}

export const EventAPI = new EventAnalyticsAPI()
