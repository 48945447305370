import { Box, Button, Typography } from '@mui/material'

export const ThirdSearchInfoModal = ({ changeModal }) => {
    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '550px',
                gap: '20px',
                zIndex: '2500',
            }}
        >
            <Box>
                <Typography sx={{ mx: 'auto', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>
                    Primary Live Top Movers
                </Typography>
                <Box
                    sx={{
                        boxShadow: '0px 2px 11px -2px rgba(0,0,0,0.1);',
                        p: '10px',
                        mt: '6px',
                        bgcolor: '#ECF0F1',
                        borderRadius: '10px',
                    }}
                >
                    <Typography sx={{ mx: 'auto', fontSize: '15px', textAlign: 'start' }}>
                        A real-time list of top primary events on Ticketmaster, ranked by ticket sales quantity or
                        percentage changes within the last 20-36 hours. Events are updated constantly and stay on the
                        list for a minimum of 20 hours and a maximum of 36 hours.
                    </Typography>
                </Box>
            </Box>

            <Button
                type="submit"
                onClick={() => {
                    changeModal('thirdInfoModal')
                }}
                sx={{ width: '80px', bgcolor: 'black', ':hover': { bgcolor: 'black' } }}
            >
                Close
            </Button>
        </Box>
    )
}
