import { useCallback, useEffect, useState } from 'react'
import { Box, InputBase, InputBaseProps, List, ListItemButton, Typography } from '@mui/material'
import { debounce } from 'lodash'
import SearchIcon from '@mui/icons-material/Search'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import { CustomTooltip } from '../dashboard/generalComponents/StyledTooltip'
import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'

const DebouncedInput = ({
    onSearch,
    value,
    debounceTime = 300,
    disableDebounce,
    suggestions,
    handleSearchableChange,
    ...rest
}: InputBaseProps & {
    value: string
    onSearch: (value: string) => void
    debounceTime?: number
    disableDebounce?: boolean
    suggestions?: any
    handleSearchableChange?: (value: string) => void
}) => {
    // Local state to store the current input value
    const [localValue, setLocalValue] = useState(value)
    const [showTooltip, setShowTooltip] = useState(false)
    const [isOnFocus, setIsOnFocus] = useState(false)
    const [isFocusOnSuggestions, setIsFocusOnSuggestions] = useState(false)

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    // Debounced function to call onSearch after debounceTime
    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            onSearch(searchValue)
        }, debounceTime),
        [onSearch, debounceTime]
    )

    const debouncedShowTooltip = useCallback(
        debounce(() => {
            setShowTooltip(true)
            setTimeout(() => {
                setShowTooltip(false)
            }, 5000)
        }, 750),
        []
    )

    useEffect(() => {
        return () => {
            debouncedSearch.cancel()
            debouncedShowTooltip.cancel()
        }
    }, [debouncedSearch, debouncedShowTooltip])

    // Handle input change
    const handleChange = (event) => {
        const { value } = event.target
        setLocalValue(value)

        if (handleSearchableChange) {
            handleSearchableChange(value)
        }

        if (!disableDebounce) {
            debouncedSearch(value)
        }

        setShowTooltip(false)
        debouncedShowTooltip()
    }

    const handleKeyPress = (event) => {
        if (disableDebounce && event.key === 'Enter') {
            onSearch(localValue)
            setIsFocusOnSuggestions(false)
            setIsOnFocus(false)
        }
    }

    const handleClearInput = () => {
        setLocalValue('')
        setShowTooltip(false) // Hide tooltip on clear
        if (disableDebounce) {
            onSearch('')
        } else {
            debouncedSearch('')
        }
    }

    return (
        <Box sx={{ position: 'relative', display: 'flex', width: '100%', alignItems: 'center' }}>
            {!disableDebounce || localValue === value ? (
                <SearchIcon fontSize="small" sx={{ color: '#667085', fontSize: '20px', mr: '8px' }} />
            ) : (
                <CustomTooltip title="Press Enter or click to search" arrow placement="top" open={showTooltip}>
                    <FindReplaceIcon
                        fontSize="small"
                        sx={{ color: '#667085', fontSize: '20px', mr: '8px', cursor: 'pointer' }}
                        onClick={() => onSearch(localValue)}
                    />
                </CustomTooltip>
            )}
            <InputBase
                {...rest}
                value={localValue}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                onBlur={() => {
                    if (disableDebounce && !isFocusOnSuggestions) {
                        onSearch(localValue)
                    }
                    setIsOnFocus(false)
                }}
                onFocus={() => {
                    setIsOnFocus(true)
                }}
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ 'aria-label': 'search' }}
            />
            {localValue?.length > 0 && (
                <Typography
                    sx={{
                        position: 'absolute',
                        right: '0px',
                        cursor: 'pointer',
                        color: '#667085',
                    }}
                    onClick={handleClearInput}
                >
                    X
                </Typography>
            )}
            {(isOnFocus || isFocusOnSuggestions) && suggestions?.artists?.length > 0 && localValue?.length >= 4 && (
                <List
                    sx={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        bgcolor: 'background.paper',
                        boxShadow: 3,
                        zIndex: 1000,
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}
                    onMouseLeave={() => setIsFocusOnSuggestions(false)}
                    onMouseEnter={() => setIsFocusOnSuggestions(true)}
                >
                    {suggestions.artists.map((suggestion, index) => (
                        <ListItemButton
                            key={index}
                            sx={{ padding: '8px 16px' }}
                            onClick={() => {
                                onSearch(suggestion)
                                setIsFocusOnSuggestions(false)
                                setIsOnFocus(false)
                            }}
                        >
                            <PersonIcon sx={{ color: '#667085', fontSize: '20px', mr: '8px' }} />
                            {suggestion}
                        </ListItemButton>
                    ))}
                    {suggestions.venues.map((suggestion, index) => (
                        <ListItemButton
                            key={index}
                            sx={{ padding: '8px 16px' }}
                            onClick={() => {
                                onSearch(suggestion)
                                setIsFocusOnSuggestions(false)
                                setIsOnFocus(false)
                            }}
                        >
                            <PlaceIcon sx={{ color: '#667085', fontSize: '20px', mr: '8px' }} />
                            {suggestion}
                        </ListItemButton>
                    ))}
                </List>
            )}
        </Box>
    )
}

export default DebouncedInput
