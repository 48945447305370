import { Link, Stack, Typography } from '@mui/material'
import PATHS from 'src/common/constants/paths'
import { TableTypography } from './TableTypography'
import { CustomTooltip } from 'src/components/dashboard/generalComponents/StyledTooltip'
import SearchIcon from '@mui/icons-material/Search'

export const ClickableVenue = ({ venueName }: { venueName: string }) => {
    return (
        <Link
            href={`${PATHS.DASHBOARD.EVENT_ANALYTICS}?secondSearch=${venueName}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': {
                    color: 'blue',
                },
            }}
        >
            <CustomTooltip
                title={
                    <Stack direction={'row'} alignItems={'center'}>
                        <SearchIcon sx={{ fontSize: '16px' }} />
                        <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>Search for this venue</Typography>
                    </Stack>
                }
            >
                <TableTypography>{venueName}</TableTypography>
            </CustomTooltip>
        </Link>
    )
}
