import { combineReducers } from '@reduxjs/toolkit'

// FIXME:
// Dummy reducer to avoid the error
const dummyReducer = (state = {}, action: any) => {
    return state
}

const rootReducer = combineReducers({ dummy: dummyReducer })

export default rootReducer
