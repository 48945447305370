import { Box, Slider, SliderProps, Typography, Stack } from '@mui/material'
import { useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { CustomSwitch } from 'src/components/general/CustomSwitch'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { StyledTextField } from 'src/components/general/StyledInput'

interface StyledSliderProps {
    colordefault?: string
}

export const StyledSlider = styled(Slider)<StyledSliderProps>(({ colordefault = '#000000', theme, disabled }) => ({
    color: colordefault,
    height: 8,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: 'white',
        border: `solid ${disabled ? 'rgb(181, 181, 195)' : colordefault}`,
        boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
    },
    '& .MuiSlider-valueLabel': {
        zIndex: '0',
        top: 57,
        backgroundColor: disabled ? '#EAECF0' : '#FFFFFF',
        color: 'black',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        padding: '8px 12px',
        boxShadow: '0px 12px 16px -4px #10182814, 0px 4px 6px -2px #10182808',
        border: 'solid #EAECF0',
        borderColor: disabled ? 'rgb(220, 220, 230)' : '#EAECF0',
        borderRadius: '8px',
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#EAECF0',
    },
}))

const formatStartingValueForScaling = (defaultSliderValue: number[], scalingStart: number, scalingStrength: number) => {
    return defaultSliderValue.map((elem) => {
        if (elem < scalingStart) return elem
        else return (elem - scalingStart) / scalingStrength + scalingStart
    })
}

const SliderFilter = ({
    id,
    scalingStart,
    scalingStrength,
    label,
    handleChangeGlobal,
    onSwitchChange,
    disableSwap,
    disabled = false,
    colordefault,
    onClick,
    switcher,
    valueLabelFormat = (value, index) => value,
    max,
    defaultSliderValue,
    note,
    value,
}: SliderProps & {
    handleChangeGlobal: (event: any, newValue: number | number[], name: string) => void
    scalingStart?: number
    switcher?: boolean
    scalingStrength?: number
    label: string
    switchValue?: boolean
    colordefault?: string
    defaultSliderValue: {}
    note?: string
    onSwitchChange?: (event: any, checked: boolean) => void
    value: number[]
}) => {
    const [isHover, setIsHover] = useState(false)

    const localValue = useMemo(() => {
        console.log('memo', value)
        if (scalingStart) return formatStartingValueForScaling(value as number[], scalingStart, scalingStrength)
        else return value as number[]
    }, [scalingStart, scalingStrength, value])

    const scaleItem = (item: number) => {
        if (item > scalingStart) {
            let newItem = item - scalingStart
            return scalingStart + newItem * scalingStrength
        } else {
            return item
        }
    }

    const valueLabelFormatting = () => {
        if (typeof valueLabelFormat === 'string') return valueLabelFormat
        if (scalingStart) return (value, index) => valueLabelFormat(scaleItem(value), index)
        else return (value, index) => valueLabelFormat(value, index)
    }

    const handleScalingChange = (event: Event, newValue: number[]) => {
        handleChangeGlobal(
            {},
            newValue.map((elem) => scaleItem(elem)),
            id
        )
    }

    const handleSliderChange = (event: Event, newValue: number[], activeThumb: number) => {
        handleChangeGlobal({}, newValue, id)
    }

    const handleInputChange = (event: any, index: number) => {
        let targetValue = event.target.value
        if (targetValue === '' || /^[0-9]*$/.test(targetValue)) {
            targetValue = targetValue === '' ? '' : parseInt(targetValue, 10)
        }
        const newValue = [...value]
        newValue[index] = targetValue
        handleChangeGlobal({}, newValue, id)
    }

    return (
        <Box
            sx={{
                width: '100%',
                paddingTop: '10px',
            }}
            onClick={onClick}
        >
            <Box flexDirection="row" display="flex">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>{label}</Typography>
                    {onSwitchChange && (
                        <CustomSwitch checked={!disabled} checkedcolor={colordefault} onChange={onSwitchChange} />
                    )}
                </Box>
                {note && (
                    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <InfoOutlinedIcon
                            onMouseLeave={() => setIsHover(false)}
                            onMouseEnter={() => setIsHover(true)}
                            sx={{ height: '26px', width: '26px', padding: '5px' }}
                        />
                        <Box
                            sx={{
                                display: isHover ? 'block' : 'none',
                                position: 'absolute',
                                bgcolor: 'rgba(30,30,30,.8)',
                                width: '300px',
                                zIndex: '1600',
                                p: '10px',
                                right: '-300px',
                                top: '0px',
                                borderRadius: '4px',
                                color: 'white',
                            }}
                        >
                            <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>{note}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>

            <StyledSlider
                colordefault={colordefault}
                disabled={disabled}
                value={localValue}
                onChange={scalingStart ? handleScalingChange : handleSliderChange}
                valueLabelDisplay={'off'}
                valueLabelFormat={valueLabelFormatting()}
                max={max}
                disableSwap={disableSwap}
            />

            <Stack direction="row" justifyContent="space-between">
                {value.map((val, index) => (
                    <StyledTextField
                        key={index}
                        value={val}
                        type="text"
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={() => {
                            if (!String(val).length) {
                                const newValue = [...value]
                                newValue[index] = defaultSliderValue[id][index]
                                handleChangeGlobal({}, newValue, id)
                            }
                        }}
                        inputProps={{
                            inputMode: 'numeric', // Helps to show numeric keyboard on mobile
                            min: 0,
                            max: max,
                            step: 1,
                            pattern: '[0-9]*', // Ensures that only numeric input is allowed
                        }}
                        sx={{
                            width: '60px',
                            '& .MuiInputBase-input': { textAlign: index === 0 ? 'left' : 'right' },
                            borderBottom: `1.9px solid ${colordefault}`,
                        }}
                        disabled={disabled}
                    />
                ))}
            </Stack>
        </Box>
    )
}

export default SliderFilter
