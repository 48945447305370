import useModal from './useModal'
import { useCustomPagination } from './usePagination'
import React, { useEffect, useState } from 'react'
import { artistsDatumInt, otherPlatform } from 'src/types/local/upSalesResp'
import { SliderActiveProps, SliderValueProps } from 'src/types/local/slidervalueprops'
import axiosClient from 'src/utils/axios'
import { GET_CATEGORIES_QUERY_KEY } from 'src/common/constants/queryKeys'
import { PresalesAPI } from 'src/services/presalesAPI'
import { useQuery } from '@tanstack/react-query'
import { EventType } from 'src/types/local/customTableTypes'
import { FrontEndError } from 'src/utils/error'
import { platformSelectArray as platformSelectArrayDefault } from 'src/common/constants/overview'

const platformSelectArray = [...platformSelectArrayDefault, { name: 'stubhub', tag: 'Stubhub W/O Primary' }]

export const defaultSliderValue: SliderValueProps = {
    spotifyRankValue: [0, 100],
    capacityValue: [0, 100000],
    priceValue: [0, 5000],
    primaryValue: [0, 30000],
    secondaryValue: [0, 30000],
    sellouts: [0, 100],
    numberChange: [1, 2000],
    percentChange: [0, 100],
}

const useTopMovers = () => {
    const { isModalOpen, changeModal, handleChangeModal, modals } = useModal()
    const { page, perPage, setAllEntries, totalPages, allEntries, handleChangePage, handleChangePerPage } =
        useCustomPagination()

    const [movement, setMovement] = useState('day_movement')

    const [switcher, setSwitcher] = useState(false)
    const [filterState, setFilterState] = useState(false)

    const [popupText, setPopupText] = useState('')

    const [firstSearchValueInput, setFirstSearchValueInput] = useState('')
    const [secondSearchValueInput, setSecondSearchValueInput] = useState('')

    const [category, setCategory] = useState([])
    const [marketplace, setMarketplace] = useState(platformSelectArray.map((e) => e.name))

    const [selectedId, setSelectedId] = useState('')
    const [selectedArtist, setSelectedArtist] = useState<artistsDatumInt>()
    const [selectedEvent, setSelectedEvent] = useState<EventType>()
    const [selectedMeta, setSelectedMeta] = useState<otherPlatform[]>()

    const [selectedArtistId, setSelectedArtistId] = useState<string>()

    const [showCalendar, setShowCalendar] = useState(false)
    const [sorting, setSorting] = useState<string>('day_movement.ticketsCountDiffPercent desc')

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [fromPresaleDate, setFromPresaleDate] = useState(null)
    const [toPresaleDate, setToPresaleDate] = useState(null)

    const [artistsArray, setArtistsArray] = useState<artistsDatumInt[]>()
    const [excluded, setExcluded] = useState(['ended', 'cancelled'])

    useEffect(() => {
        if (sorting.includes('.') && sorting.split('.')[0] !== movement) {
            setSorting(movement + '.' + sorting.split('.')[1])
        }
    }, [movement, sorting])

    useEffect(() => {
        if (selectedEvent && selectedEvent?.artistsStat?.length > 0) {
            const dataArray = selectedEvent?.artistsStat?.sort((a, b) => {
                if (a?.spotifyAPI?.popularity && b?.spotifyAPI?.popularity) {
                    if (a?.spotifyAPI?.popularity < b?.spotifyAPI?.popularity) {
                        return 1
                    }
                    if (a?.spotifyAPI?.popularity > b?.spotifyAPI?.popularity) return -1
                    return 0
                }
                if (a?.spotifyAPI?.popularity && !b?.spotifyAPI?.popularity) return -1
                if (b?.spotifyAPI?.popularity && !a?.spotifyAPI?.popularity) return 1
                return 0
            })
            setArtistsArray(dataArray)

            //@ts-ignore
            setSelectedArtistId(dataArray[0]._id)
        }
    }, [selectedEvent])

    const { data: dataCategories } = useQuery({
        queryKey: [GET_CATEGORIES_QUERY_KEY],
        queryFn: PresalesAPI.getCategories,
    })

    useEffect(() => {
        if (dataCategories?.data) setCategory(dataCategories.data.data)
    }, [dataCategories])

    const handleSetStartDate = (value, context) => {
        setStartDate(value)
    }

    const handleSetEndDate = (value, context) => {
        setEndDate(value)
    }

    const handleSetFromPresaleDate = (value, context) => {
        setFromPresaleDate(value)
    }

    const handleSetToPresaleDate = (value, context) => {
        setToPresaleDate(value)
    }

    const [sliderValue, setSliderValue] = useState<SliderValueProps>(defaultSliderValue)

    const [isDisabledFilters, setIsDisabledFilters] = useState<SliderActiveProps>({
        spotifyRankValue: true,
        capacityValue: true,
        priceValue: true,
        primaryValue: true,
        secondaryValue: true,
        sellouts: true,
        numberChange: true,
        percentChange: true,
    })

    const handleChangeActive = (value: string) => {
        setIsDisabledFilters((prevValue) => {
            return {
                ...prevValue,
                [value]: false,
            }
        })
    }

    const handleDisableAll = () => {
        setIsDisabledFilters((prevValue) => ({
            ...prevValue,
            spotifyRankValue: true,
            capacityValue: true,
            priceValue: true,
            primaryValue: true,
            secondaryValue: true,
            sellouts: true,
            numberChange: true,
            percentChange: true,
        }))

        // Set slider values to default
        setSliderValue((prevValue) => defaultSliderValue)

        // reset dates
        setStartDate('')
        setExcluded([])
        setEndDate('')
        setCategory([])
        setMarketplace([])
    }
    const handleDisableSingle = (key: string, value: boolean) => {
        setIsDisabledFilters((prevValue) => {
            return {
                ...prevValue,
                [key]: !prevValue[key],
            }
        })
    }
    const handleChangeFirstSearchInput = (value: string) => {
        setFirstSearchValueInput(value)
    }
    const handleChangeSecondSearchInput = (value: string) => {
        setSecondSearchValueInput(value)
    }

    const handleChangeSlider = (event: Event, newValue: number | number[], name: string) => {
        setSliderValue((prevValue) => ({
            ...prevValue,
            [name]: newValue as number[],
        }))
    }

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    const handleInputEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    // Query for event analytics

    const fetchSecondary = (eventId: string) => {
        axiosClient()
            .get(`/api/analytics/secondary-active-listing?eventId=${eventId}`)
            .catch((err) => {
                FrontEndError(err)
            })
    }

    return {
        defaultSliderValue,
        sliderValue,
        setSliderValue,

        firstSearchValueInput,
        secondSearchValueInput,
        handleChangeFirstSearchInput,
        handleChangeSecondSearchInput,
        handleEnterKeyPress,
        handleInputEnter,

        filterState,
        selectedId,
        popupText,

        page,
        perPage,
        totalPages,
        allEntries,

        dataCategories,
        category,
        setCategory,

        marketplace,

        modals,
        isDisabledFilters,
        showCalendar,
        selectedArtist,
        selectedEvent,
        selectedMeta,

        startDate,
        endDate,
        handleSetStartDate,
        handleSetEndDate,

        fromPresaleDate,
        toPresaleDate,
        handleSetFromPresaleDate,
        handleSetToPresaleDate,

        switcher,
        sorting,
        selectedArtistId,
        artistsArray,
        setAllEntries,
        handleDisableAll,
        handleChangeModal,
        handleChangePage,
        handleChangePerPage,
        handleChangeToggle: handleDisableSingle,
        setShowCalendar,
        setPopupText,
        setMarketplace,
        setSelectedArtist,
        setSelectedEvent,
        setSelectedId,
        setSelectedMeta,
        fetchSecondary,
        setSorting,

        handleChangeActive,
        handleChangeSlider,

        changeModal,
        isModalOpen,
        setFilterState,
        setSwitcher,

        excluded,
        setExcluded,

        movement,
        setMovement,
    }
}

export default useTopMovers
