import { IconButton, Paper, Stack } from '@mui/material'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import DebouncedInput from './DebouncedInput'

interface ISearchBarProps {
    value: string
    debounceTime?: number
    onInfoClick?: () => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    placeholder: string
    onSearch: (value: string) => void
    disableDebounce?: boolean
    handleSearchableChange?: (value: string) => void
    suggestions?: string[]
}

export const SearchBar = ({
    onInfoClick,
    value,
    debounceTime = 300,
    onKeyDown,
    onSearch,
    placeholder,
    disableDebounce = false,
    handleSearchableChange,
    suggestions,
}: ISearchBarProps) => {
    return (
        <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
            {onInfoClick && (
                <IconButton sx={{ width: '26px', height: '26px', mr: '4px' }} onClick={onInfoClick}>
                    <HelpOutlineOutlinedIcon sx={{ width: '20px', height: '20px', color: '#667085' }} />
                </IconButton>
            )}
            <Paper
                component="form"
                sx={{
                    border: '1px solid',
                    borderColor: '#D0D5DD',
                    borderRadius: '8px',
                    p: '3px 14px',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: 0,
                    width: '100%',
                }}
            >
                <DebouncedInput
                    onKeyDown={disableDebounce ? onKeyDown : undefined}
                    value={value}
                    onSearch={onSearch}
                    sx={{ fontSize: '16px', lineHeight: '24px', p: '0', width: '100%' }}
                    placeholder={placeholder}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    debounceTime={debounceTime}
                    disableDebounce={disableDebounce}
                    handleSearchableChange={handleSearchableChange}
                    suggestions={suggestions}
                />
            </Paper>
        </Stack>
    )
}
