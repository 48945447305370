import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Box, Button, Chip, InputBase, ListItemText, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import { EventAPI } from 'src/services/eventAnalyticsAPI'
import { ApiDeleteDropCheckRequest } from 'src/types/api/apiEventAnalyticsTypes'
import { dropCheckInt } from 'src/types/local/primaryFavorite'
import axiosClient from 'src/utils/axios'
import { StyledCheckBox, StyledMenuItem } from '../../generalComponents/TableComponents'
import ModalSelect from '../ModalSelect'
import CircularProgress from '@mui/material/CircularProgress'

import { GET_SECTIONS_QUERY_KEY, GET_TICKET_TYPES } from 'src/common/constants/queryKeys'
import { useUserLimits } from 'src/hooks/useLimitsContext'

const excludingOpts = ['Exclude Platinum', 'Exclude Accessibility']
const basicTicketTypeOptions = ['Standard', ...excludingOpts]

export default function FilterDropModal({
    setFilterModal = () => {},
    setDropModal,
    eventId,
    DeleteDrop,
    selectedDrop = [],
    handleDropAdd = () => {},
    setPopupText,
    selectedEvent,
}: {
    eventId: string
    selectedDrop?: any[]
    handleFetch?: (id?: string) => void
    handleDropAdd?: (id?: string, dropCheck?: dropCheckInt) => void
    DeleteDrop?: (params: ApiDeleteDropCheckRequest) => void
    setFilterModal?: React.Dispatch<SetStateAction<boolean>>
    setDropModal?: React.Dispatch<SetStateAction<boolean>>
    setPopupText?: (value: string) => void
    selectedEvent?: any
}) {
    const { wasteTmDropChecker } = useUserLimits()

    const [initiatedEventId, setInitiatedEventId] = useState<string>('')
    const [initiatedAlertId, setInitiatedAlertId] = useState<string>('')

    const [selectedTicketsTypes, setSelectedTicketsTypes] = useState<string[]>([])
    const [selectedQuantity, setSelectedQuantity] = useState<string>('1')

    const [selectedSections, setSelectedSections] = useState<string[]>([])
    // const [ticketTypeOptions, setTicketTypeOptions] = useState([])

    const [sliderValue, setSliderValue] = useState<number[]>([0, 800])

    const [newURL, setNewUrl] = useState('')
    const [isNewUrlValidated, setNewUrlValidated] = useState(false)
    const [URLs, setURLs] = useState([])

    const {
        data: dataSectionOptions,
        isLoading: isSectionLoading,
        isRefetching: isSectionRefetching,
        isError: isSectionError,
    } = useQuery({
        queryKey: [GET_SECTIONS_QUERY_KEY, eventId],
        queryFn: () => axiosClient().get(`/api/events/get-sections/${eventId}`),
        enabled: !!eventId,
    })

    const sectionOptions = useMemo(() => {
        return dataSectionOptions ? [...dataSectionOptions?.data?.data] : []
    }, [dataSectionOptions])

    const {
        data: ticketTypesResponse,
        isLoading: isTicketTypesLoading,
        isRefetching: isTicketTypesRefetching,
        isError: isTicketTypesError,
    } = useQuery({
        queryKey: [GET_TICKET_TYPES, eventId],
        queryFn: () => axiosClient().get(`/api/events/get-ticket-types/${eventId}`),
        enabled: !!eventId,
    })

    const ticketTypeOptions = useMemo(() => {
        if (!ticketTypesResponse || isTicketTypesLoading || isTicketTypesRefetching || isTicketTypesError) return []

        const ticketsArr = (ticketTypesResponse?.data?.data as string[]).filter(
            (ticket) => !basicTicketTypeOptions.includes(ticket)
        )
        if (ticketsArr.indexOf('All') > -1) ticketsArr.splice(ticketsArr.indexOf('All'))
        return [...basicTicketTypeOptions, ...ticketsArr]
    }, [ticketTypesResponse, isTicketTypesLoading, isTicketTypesRefetching, isTicketTypesError])

    useEffect(() => {
        const urlPattern = /^(https:\/\/)?(www\.)?ticketmaster\.com\/[a-zA-Z0-9\-]+\/event\/[a-zA-Z0-9]+$/
        const isValid = !URLs.includes(newURL) && urlPattern.test(newURL)
        setNewUrlValidated(isValid)
    }, [URLs, newURL])

    const isLoading = useMemo(
        () =>
            !dataSectionOptions ||
            isSectionLoading ||
            isSectionRefetching ||
            isSectionError ||
            !ticketTypesResponse ||
            isTicketTypesLoading ||
            isTicketTypesRefetching ||
            isTicketTypesError,
        [
            dataSectionOptions,
            isSectionLoading,
            isSectionRefetching,
            isSectionError,
            ticketTypesResponse,
            isTicketTypesLoading,
            isTicketTypesRefetching,
            isTicketTypesError,
        ]
    )

    useEffect(() => {
        if (isLoading) {
            setInitiatedEventId('')
            setInitiatedAlertId('')
            setSelectedQuantity('1')
            return
        }

        if (
            selectedDrop[0]
                ? initiatedAlertId === selectedDrop[0]?._id &&
                  selectedSections.length !== selectedDrop[0]?.areas?.length
                : initiatedEventId === eventId
        ) {
            return
        }

        if (selectedDrop[0]?.areas?.length > 0) {
            setSelectedSections(selectedDrop[0]?.areas || [])
            setSelectedQuantity(selectedDrop[0]?.quantity || '1')
            setSelectedTicketsTypes(selectedDrop[0]?.ticket_types || [])
            setSliderValue([selectedDrop[0]?.price_range_start || 0, selectedDrop[0]?.price_range_end || 800])
        } else {
            setSelectedSections(sectionOptions)
            setSelectedTicketsTypes(
                [...basicTicketTypeOptions, ...ticketTypesResponse.data.data].filter((v) => !excludingOpts.includes(v))
            )
        }

        // Update the event and alert IDs to track changes
        setInitiatedEventId(eventId)
        setInitiatedAlertId(selectedDrop[0]?._id)
    }, [
        eventId,
        initiatedAlertId,
        initiatedEventId,
        isLoading,
        sectionOptions,
        selectedDrop,
        selectedSections.length,
        ticketTypesResponse,
    ])

    const handleTicketChange = (event: any) => {
        const selectedOptions = event.target.value.filter((v) => v !== 'All Types')
        if (selectedOptions.includes('All')) {
            setSelectedTicketsTypes([
                ...(ticketTypeOptions
                    ? ticketTypeOptions.filter(
                          (option) => option !== 'Exclude Platinum' && option !== 'Exclude Accessibility'
                      )
                    : selectedOptions),
            ])
            return
        }
        if (selectedOptions.includes('Unselect_All')) {
            setSelectedTicketsTypes([])
            return
        }
        setSelectedTicketsTypes(selectedOptions)
    }

    const handleSectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let selectedOptions = event.target.value as string[]
        if (selectedOptions.includes('Entire event')) {
            setSelectedSections((prev) => {
                const result =
                    prev.length === sectionOptions.length
                        ? selectedOptions.length > 1
                            ? prev.filter((prev) => !selectedOptions.filter((v) => v !== 'Entire event').includes(prev))
                            : []
                        : sectionOptions
                return result
            })
            return
        }

        setSelectedSections(selectedOptions)
    }

    const handleCancel = () => {
        selectedEvent && setFilterModal(true)
        setDropModal(true)
    }

    const handleChangeQuantity = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedQuantity(Math.max(event.target.value as number, 1).toString())
    }

    const onAddDropCheck = async () => {
        if (!eventId) {
            return
        }
        EventAPI.addDropCheckAlert({
            eventId,
            ticket_types: selectedTicketsTypes,
            quantity: selectedQuantity,
            areas: selectedSections,
            price_range_start: sliderValue[0],
            price_range_end: sliderValue[1],
        }).then(async () => {
            wasteTmDropChecker()
            setPopupText('Added Dropchecker Alert')
            setDropModal(true)
            handleDropAdd()
            setInitiatedEventId('')
            setInitiatedAlertId('')
        })
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',
                flexDirection: 'column',
                width: '550px',
                gap: '20px',
                zIndex: '1500',
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography sx={{ fontSize: '24px', fontWeight: '600', color: '#1A63FF' }}>
                    Add Dropchecker Event
                </Typography>
                {selectedEvent && (
                    <Typography sx={{ fontSize: '14px', color: '#6072a6', marginTop: '16px' }}>
                        {selectedEvent?.name}
                    </Typography>
                )}
            </Box>
            {!selectedEvent && !selectedDrop && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>URLs</Typography>
                    <Stack direction={'row'}>
                        <InputBase
                            sx={{
                                bgcolor: 'white',
                                border: 1,
                                borderColor: 'grey.400',
                                width: '100%',
                                borderRadius: '6px',
                                height: '45px',
                                px: 2,
                                '.Mui-disabled': {
                                    color: 'black',
                                },
                            }}
                            endAdornment={
                                <Button
                                    disabled={!isNewUrlValidated}
                                    onClick={() => {
                                        setURLs((urls) => [...urls, newURL])
                                        setNewUrl('')
                                    }}
                                    variant="contained"
                                    sx={{ width: '10px' }}
                                >
                                    +
                                </Button>
                            }
                            value={selectedEvent ? selectedEvent.meta.otherPlatforms[0].url : newURL}
                            onChange={
                                selectedEvent
                                    ? () => {}
                                    : (e) => {
                                          setNewUrl(e.target.value)
                                      }
                            }
                        />
                    </Stack>
                    {newURL && !isNewUrlValidated && (
                        <Typography color={'red'}>Please insert valid URL or check duplicates</Typography>
                    )}
                </Box>
            )}
            <Stack gap={0.5}>
                {URLs.map((keyword, index) => (
                    <Chip key={index} label={keyword} color="primary" />
                ))}
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <ModalSelect
                    handleChange={handleTicketChange}
                    multiple
                    value={isLoading ? ['Loading...'] : selectedTicketsTypes}
                    label="Ticket Type"
                >
                    {isLoading ? (
                        <StyledMenuItem key={'loading'} disabled>
                            <CircularProgress size={24} />
                            <ListItemText primary={'Loading...'} />
                        </StyledMenuItem>
                    ) : selectedTicketsTypes.length === ticketTypeOptions.length - excludingOpts.length ? (
                        <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={selectedTicketsTypes.length === ticketTypeOptions.length}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'Unselect All'} />
                        </StyledMenuItem>
                    ) : (
                        <StyledMenuItem
                            key={'All'}
                            value={'All'}
                            sx={{
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': { backgroundColor: '#e6e6e6 !important' },
                            }}
                        >
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={selectedTicketsTypes.length === ticketTypeOptions.length}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'All'} />
                        </StyledMenuItem>
                    )}
                    {ticketTypeOptions.map((ticket) => (
                        <StyledMenuItem
                            key={ticket}
                            value={ticket}
                            sx={{
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': { backgroundColor: '#e6e6e6 !important' },
                            }}
                        >
                            <StyledCheckBox
                                sx={{ color: 'background.black' }}
                                checked={selectedTicketsTypes.indexOf(ticket) > -1}
                                checkedIcon={<CheckBoxIcon sx={{ color: 'background.black' }} />}
                            />
                            <ListItemText primary={ticket} />
                        </StyledMenuItem>
                    ))}
                </ModalSelect>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Minimal Quantity</Typography>
                    <InputBase
                        sx={{
                            bgcolor: 'white',
                            border: 1,
                            borderColor: 'grey.400',
                            width: '100%',
                            borderRadius: '6px',
                            height: '45px',
                            px: 2,
                        }}
                        type="number"
                        value={String(selectedQuantity)}
                        onChange={handleChangeQuantity}
                    ></InputBase>
                </Box>
                <ModalSelect
                    handleChange={handleSectionChange}
                    multiple={true}
                    value={isLoading ? ['Loading...'] : selectedSections}
                    label="Area"
                    isAllSelected={selectedSections.length === sectionOptions.length}
                    allSelectedLabel="Entire event"
                >
                    {isLoading ? (
                        <StyledMenuItem key={'loading'} disabled>
                            <CircularProgress size={24} />
                            <ListItemText primary={'Loading...'} />
                        </StyledMenuItem>
                    ) : (
                        <StyledMenuItem key={'Entire event '} value={'Entire event'}>
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={selectedSections.length === sectionOptions.length}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'Entire event'} />
                        </StyledMenuItem>
                    )}
                    {sectionOptions.map((section) => (
                        <StyledMenuItem key={section} value={section}>
                            <StyledCheckBox
                                sx={{ color: 'background.black' }}
                                checked={selectedSections.indexOf(section) > -1}
                                checkedIcon={<CheckBoxIcon sx={{ color: 'background.black' }} />}
                            />
                            <ListItemText primary={section} />
                        </StyledMenuItem>
                    ))}
                </ModalSelect>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    width: '100%',
                    gap: '12px',
                }}
            >
                <Button
                    onClick={onAddDropCheck}
                    sx={{
                        bgcolor: '#001A63FF',
                        color: 'text.default',
                        '&:hover': { bgcolor: '#001A63FF' },
                        px: '16px',
                        py: '8px',
                    }}
                >
                    ADD EVENT
                </Button>
                {selectedDrop?.[0]?._id && (
                    <Button
                        onClick={() => {
                            DeleteDrop({ eventId: selectedDrop?.[0]?._id })
                            setDropModal(false)
                        }}
                        sx={{
                            bgcolor: '#E72929',
                            color: 'white',
                            '&:hover': { bgcolor: '#E72929' },
                            px: '16px',
                            py: '8px',
                        }}
                    >
                        DELETE
                    </Button>
                )}
                <Button
                    onClick={handleCancel}
                    sx={{
                        bgcolor: 'background.default',
                        color: 'text.primary',
                        '&:hover': { bgcolor: 'background.default' },
                        px: '16px',
                        py: '8px',
                    }}
                >
                    CANCEL
                </Button>
            </Box>
        </Box>
    )
}
