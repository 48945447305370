import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { USER_PERMISSIONS } from 'src/common/constants/adminPermissions'
import {
    GET_SCRAP_SECTION_INFORMATION_QUERY_KEY,
    GET_SCRAP_SECTION_INFORMATION_STUBHUB_QUERY_KEY,
} from 'src/common/constants/queryKeys'
import useAuth from 'src/hooks/useAuth'
import { useUserLimits } from 'src/hooks/useLimitsContext'
import RestockIcon from 'src/icons/RestockIcon'
import { EventAPI } from 'src/services/eventAnalyticsAPI'
import { StyledTableIconButton } from '../../general/table/StyledTableIconButton'
import { adminApi } from 'src/services/adminApi'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'

export function StyledActionBox({
    setSelectedId,
    event_id,
    changeModal,
    setSelectedEvent,
    setRefetchingId,
    hasPrimary,
    hasSecondary,
    updateEventLastFetchDetails,
    updateEventStatus,
    eventStatus,
    isMarkedAsViewed,
    handleSetEventAsViewed,
}: {
    event_id: string
    changeModal
    setSelectedId
    setSelectedEvent: (eventId: string) => void
    setRefetchingId: (eventId: string) => void
    hasPrimary: boolean
    hasSecondary: boolean
    updateEventLastFetchDetails: (eventId: string, update: any) => void
    updateEventStatus: (eventId: string, update: any) => void
    eventStatus
    isMarkedAsViewed: boolean
    handleSetEventAsViewed
}) {
    const [showPopUp, setShowPopUp] = useState(false)
    const [hoveredAddAlertId, setHoveredAddAlertId] = useState<any>()
    const [hoveredToFavoritesId, setHoveredToFavoritesId] = useState<any>()
    const [hoveredAddNotesId, setHoveredAddNotesId] = useState<any>()
    const [hoveredDeleteEventId, setHoveredDeleteEventId] = useState<any>()
    const { fetchCount, wasteFetch, maxFetchCount } = useUserLimits()
    const [loading, setLoading] = useState(false)
    const [, setRefetchingMessage] = useState(`You have used ${fetchCount ? fetchCount : 0}/${maxFetchCount} fetches.`)
    const [isOpenedDeleteConfirm, setIsOpenedDeleteConfirm] = useState(false)
    const [isOpenedEdit, setIsOpenedEdit] = useState(false)
    const [localEventStatus, setLocalEventStatus] = useState<
        'cancelled' | 'offsale' | 'onsale' | 'postponed' | 'rescheduled' | 'no status'
    >('no status')

    useEffect(() => {
        setLocalEventStatus(eventStatus || 'no status')
    }, [eventStatus])

    const {
        user: { permissions },
    } = useAuth()

    const {
        refetch: refetchPrimary,
        data: primaryData,
        isError: primaryError,
    } = useQuery({
        queryKey: [GET_SCRAP_SECTION_INFORMATION_QUERY_KEY],
        queryFn: () => EventAPI.getEventsScrapSectionInformation({ eventId: event_id }),
        enabled: false,
    })

    const {
        refetch: refetchSecondary,
        data: secondaryData,
        isError: secondaryError,
    } = useQuery({
        queryKey: [GET_SCRAP_SECTION_INFORMATION_STUBHUB_QUERY_KEY],
        queryFn: () => EventAPI.getEventsScrapSectionInformationStubhub({ eventId: event_id }),
        enabled: false,
    })

    useEffect(() => {
        if (primaryData?.data?.data) {
            updateEventLastFetchDetails(event_id, primaryData.data.data)
        }
        if (secondaryData?.data?.data) {
            updateEventLastFetchDetails(event_id, secondaryData.data.data)
        }
    }, [event_id, primaryData, secondaryData, updateEventLastFetchDetails])

    const handleOpenNote = () => {
        setSelectedId(event_id)
        setSelectedEvent(event_id)
        changeModal('noteModal')
    }
    const handleOpenAddToFavs = () => {
        setSelectedId(event_id)
        changeModal('saveModal')
    }
    const handleOpenAlert = () => {
        setSelectedId(event_id)
        setSelectedEvent(event_id)
        changeModal('mainModal')
    }
    const handleClickRefresh = async () => {
        if (fetchCount > 0) {
            setLoading(true)
            setRefetchingMessage('Refetching')
            setRefetchingId(event_id)
            setSelectedId(event_id)
            if (hasPrimary) {
                await refetchPrimary()
                if (primaryError) {
                    toast(`Error when scrape primary`)
                }
            }
            if (hasSecondary) {
                await refetchSecondary()
                if (secondaryError) {
                    toast(`Error when scrape secondary`)
                }
            }
            setTimeout(() => {
                wasteFetch()
                setLoading(false)
                setRefetchingMessage('Successfully fetched')
                setRefetchingId(null)
            }, 5000)
            setTimeout(() => {
                setRefetchingMessage(`You have used ${fetchCount ? fetchCount : 0}/${maxFetchCount} fetches.`)
            }, 8000)
        }
    }
    const handleClickDelete = () => {
        setIsOpenedDeleteConfirm(true)
    }
    const handleCloseDeleteDialog = () => {
        setIsOpenedDeleteConfirm(false)
    }
    const handleConfirmDelete = async () => {
        //await someApi.deleteEvent({eventId: event_id})
        handleCloseDeleteDialog()
    }

    const handleClickEdit = () => {
        setIsOpenedEdit(true)
    }

    const handleCloseEditDialog = () => {
        setIsOpenedEdit(false)
    }

    const handleUpdateEvent = async () => {
        await adminApi.UpdateEvent({
            eventId: event_id,
            currentStatus: localEventStatus !== 'no status' ? localEventStatus : null,
        })
        updateEventStatus(event_id, localEventStatus !== 'no status' ? localEventStatus : null)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: '1fr 1fr',
                    gap: '14px',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    <StyledTableIconButton
                        onClick={handleOpenAlert}
                        size="small"
                        onMouseEnter={() => setHoveredAddAlertId(event_id)}
                        onMouseLeave={() => setHoveredAddAlertId(null)}
                    >
                        <AddAlertOutlinedIcon sx={{ color: '#E4933D', width: '19px' }} />
                    </StyledTableIconButton>
                    <Box
                        sx={{
                            display: hoveredAddAlertId === event_id ? 'block' : 'none',
                            position: 'absolute',
                            bgcolor: 'rgba(30,30,30,.8)',
                            width: '80px',
                            zIndex: '1600',
                            p: '10px',
                            right: '20px',
                            bottom: '25px',
                            borderRadius: '4px',
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        Set Alerts
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    <StyledTableIconButton
                        onClick={handleSetEventAsViewed}
                        size="small"
                        onMouseEnter={() => setHoveredAddNotesId(event_id)}
                        onMouseLeave={() => setHoveredAddNotesId(null)}
                    >
                        <VisibilityIcon sx={{ color: isMarkedAsViewed ? '#6363ff' : '#d0d5dd', width: '19px' }} />
                    </StyledTableIconButton>
                    <Box
                        sx={{
                            display: hoveredAddNotesId === event_id ? 'block' : 'none',
                            position: 'absolute',
                            bgcolor: 'rgba(30,30,30,.8)',
                            width: '80px',
                            zIndex: '1600',
                            p: '10px',
                            bottom: '25px',
                            right: '20px',
                            borderRadius: '4px',
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        {isMarkedAsViewed ? 'Remove from Viewed' : 'Mark as Viewed'}
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    <StyledTableIconButton
                        onClick={handleOpenAddToFavs}
                        size="small"
                        onMouseEnter={() => setHoveredToFavoritesId(event_id)}
                        onMouseLeave={() => setHoveredToFavoritesId(null)}
                    >
                        <AddBoxOutlinedIcon sx={{ color: '#E4933D', width: '19px' }} />
                    </StyledTableIconButton>
                    <Box
                        sx={{
                            display: hoveredToFavoritesId === event_id ? 'block' : 'none',
                            position: 'absolute',
                            bgcolor: 'rgba(30,30,30,.8)',
                            width: '140px',
                            zIndex: '1600',
                            p: '10px',
                            bottom: '25px',
                            right: '20px',
                            borderRadius: '4px',
                            color: 'white',
                            fontSize: '12px',
                            fontWeight: '500',
                        }}
                    >
                        Add to "My Favorites"
                    </Box>
                </Box>
                <Box
                    sx={{ position: 'relative' }}
                    onMouseLeave={() => setShowPopUp(false)}
                    onMouseEnter={() => setShowPopUp(true)}
                >
                    <StyledTableIconButton disabled onClick={() => handleClickRefresh()} size="small">
                        <RestockIcon
                            sx={{
                                width: '20px',
                                height: '20px',
                                stroke: '#E4933D',
                                animation: loading ? 'spin 3000ms infinite linear' : null,
                                '@keyframes spin': {
                                    '0%': {
                                        transform: 'rotate(360deg)',
                                    },
                                    '100%': {
                                        transform: 'rotate(0deg)',
                                    },
                                },
                            }}
                        />
                        <Box
                            sx={{
                                display: showPopUp || loading ? 'block' : 'none',
                                position: 'absolute',
                                bgcolor: 'rgba(30,30,30,.8)',
                                width: '120px',
                                zIndex: '1600',
                                p: '10px',
                                right: '10px',
                                bottom: '25px',
                                borderRadius: '4px',
                                color: 'white',
                                fontSize: '10px',
                                fontWeight: '500',
                            }}
                        >
                            {/* {!isAXS ? ( */}
                            {/* <> */}
                            {/* {!loading && <Typography sx={{ fontSize: '12px' }}>Refetch Live Data</Typography>} */}
                            {/* <Typography sx={{ fontSize: '12px' }}>{refetchingMessage}</Typography> */}
                            {/* </> */}
                            {/* ) : ( */}
                            <Typography sx={{ fontSize: '12px' }}>
                                We do not support this action at this time.
                            </Typography>
                            {/* )} */}
                        </Box>
                    </StyledTableIconButton>
                </Box>
                {permissions?.includes(USER_PERMISSIONS.MANAGE_DELETE_EVENT) && (
                    <Box>
                        <Dialog open={isOpenedDeleteConfirm} onClose={handleCloseDeleteDialog}>
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogContent>Are you sure you want to delete this event?</DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="warning">
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirmDelete} color="error">
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Box sx={{ position: 'relative' }}>
                            <StyledTableIconButton
                                onClick={handleClickDelete}
                                size="small"
                                onMouseEnter={() => setHoveredDeleteEventId(event_id)}
                                onMouseLeave={() => setHoveredDeleteEventId(null)}
                            >
                                <DeleteOutlineIcon sx={{ color: 'red', width: '20px' }} />
                            </StyledTableIconButton>
                            <Box
                                sx={{
                                    display: hoveredDeleteEventId === event_id ? 'block' : 'none',
                                    position: 'absolute',
                                    bgcolor: 'rgba(30,30,30,.8)',
                                    width: '90px',
                                    zIndex: '1600',
                                    p: '10px',
                                    right: '20px',
                                    borderRadius: '4px',
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                }}
                            >
                                Delete Event
                            </Box>
                        </Box>
                    </Box>
                )}
                {permissions?.includes(USER_PERMISSIONS.MANAGE_EDIT_EVENT) && (
                    <Box>
                        <Dialog open={isOpenedEdit} onClose={handleCloseEditDialog} fullWidth>
                            <DialogTitle>Edit event</DialogTitle>
                            <DialogContent>
                                <Stack>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            value={localEventStatus}
                                            // @ts-ignore
                                            onChange={(e) => setLocalEventStatus(e.target.value)}
                                            sx={{ width: '300px' }}
                                        >
                                            <MenuItem value="no status">No status</MenuItem>
                                            <MenuItem value="cancelled">Cancelled</MenuItem>
                                            <MenuItem value="offsale">Off sale</MenuItem>
                                            <MenuItem value="onsale">On sale</MenuItem>
                                            <MenuItem value="postponed">Postponed</MenuItem>
                                            <MenuItem value="rescheduled">Rescheduled</MenuItem>
                                        </Select>
                                    </Stack>
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseEditDialog} color="warning">
                                    Cancel
                                </Button>
                                <Button onClick={handleUpdateEvent} color="error">
                                    Update
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Box sx={{ position: 'relative' }}>
                            <StyledTableIconButton onClick={handleClickEdit} size="small">
                                <EditIcon sx={{ color: '#4949dd', width: '20px' }} />
                            </StyledTableIconButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}
