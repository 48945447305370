import { Box, Typography, Button, ButtonGroup, Tooltip, InputBase } from '@mui/material'
import React, { SetStateAction, useEffect, useState } from 'react'
import { useUserLimits } from 'src/hooks/useLimitsContext'
import { instance } from 'src/utils/axios'
import { FrontEndError } from 'src/utils/error'

interface SaveModalProps {
    setState: React.Dispatch<SetStateAction<boolean>>
    event_id: string
    setPopupText: (value: string) => void
}

export function SavingModal({ setState, event_id, setPopupText }: SaveModalProps) {
    const {
        primaryFavCount,
        maxPrimaryFavCount,
        secondaryFavCount,
        maxSecondaryFavCount,
        wastePrimaryFav,
        wasteSecondaryFav,
    } = useUserLimits()
    const [textArea, setTextArea] = useState<string>('')

    useEffect(() => {
        setTextArea('')
    }, [event_id])

    const backgroundStylesButton = (bgcolor: string) => ({
        bgcolor: bgcolor,
        color: 'text.default',
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: '600',
        py: '10px',
        '&:hover': { bgcolor: bgcolor },
    })

    const handleSave = (type: string) => {
        instance
            .post('/api/events/notes/save', { event_id, text: textArea, type })
            .catch((err) => FrontEndError(err))
            .finally(() => {
                if (type === 'all') {
                    wastePrimaryFav()
                    wasteSecondaryFav()
                } else if (type === 'my_primary') wastePrimaryFav()
                else if (type === 'my_secondary') wasteSecondaryFav()
                setPopupText(`Event Saved to ${type === 'my_primary' ? 'Primary' : 'Secondary'} Favorites`)
                setState(false)
                setTextArea('')
            })
    }

    // const handleDelete = () => {
    //     setTextArea('')
    //     instance
    //         .post('/api/events/notes/delete', { event_id })
    //         .then(() => setPopupText('Note deleted'))
    //         .catch((err) => FrontEndError(err))
    //         .finally(() => setState(false))
    // }

    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                width: '650px',
                gap: '20px',
                zIndex: '2500',
            }}
        >
            <Typography sx={{ mx: 'auto', fontSize: '18px', fontWeight: '600' }}>Save To</Typography>
            <InputBase
                multiline
                rows={6}
                sx={{
                    width: '100%',
                    minHeight: '100px',
                    paddingInline: '20px',
                    border: 1,
                    borderColor: 'grey.400',
                    backgroundColor: 'white',
                    borderRadius: '6px',
                }}
                value={textArea}
                onChange={(e) => setTextArea(e.target.value)}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%', gap: '12px' }}>
                <ButtonGroup>
                    <Tooltip
                        title={
                            <Typography variant="h6">{`Primary: ${primaryFavCount} / ${maxPrimaryFavCount}`}</Typography>
                        }
                    >
                        <Button
                            disabled={primaryFavCount >= maxPrimaryFavCount}
                            onClick={() => handleSave('my_primary')}
                            sx={backgroundStylesButton(
                                primaryFavCount >= maxPrimaryFavCount ? 'grey' : 'background.blue'
                            )}
                        >
                            Primary
                        </Button>
                    </Tooltip>
                    <Button
                        onClick={() => handleSave('all')}
                        sx={backgroundStylesButton(
                            primaryFavCount >= maxPrimaryFavCount || secondaryFavCount >= maxSecondaryFavCount
                                ? 'grey'
                                : '#07325F'
                        )}
                        disabled={primaryFavCount >= maxPrimaryFavCount || secondaryFavCount >= maxSecondaryFavCount}
                    >
                        All
                    </Button>
                    <Tooltip
                        title={
                            <Typography variant="h6">{`Secondary: ${secondaryFavCount} / ${maxSecondaryFavCount}`}</Typography>
                        }
                    >
                        <Button
                            disabled={secondaryFavCount >= maxSecondaryFavCount}
                            onClick={() => handleSave('my_secondary')}
                            sx={backgroundStylesButton(secondaryFavCount >= maxSecondaryFavCount ? 'grey' : '#0D59AB')}
                        >
                            Secondary
                        </Button>
                    </Tooltip>
                    {/* {textArea && (
                        <Button onClick={handleDelete} sx={{ ...backgroundStylesButton('#E72929') }}>
                            DELETE NOTE
                        </Button>
                    )} */}
                </ButtonGroup>
                <Button
                    onClick={() => setState(false)}
                    sx={{ border: '1px black solid', ...backgroundStylesButton('white') }}
                >
                    <Typography sx={{ color: 'black', fontWeight: '700' }}>Cancel</Typography>
                </Button>
            </Box>
        </Box>
    )
}
