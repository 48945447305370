/* eslint-disable react-hooks/rules-of-hooks */
import { isArray } from 'lodash'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type TStateObject<T = any> = {
    name: string
    initialValue: T
}

type TStateSetters = Record<string, any>

export const useUrlParamsState = ({
    handleSearch,
    initialStates,
}: {
    handleSearch: () => void
    initialStates: TStateObject[]
}) => {
    const stateSetters: TStateSetters = {}

    const [searchParams, setSearchParams] = useSearchParams()

    initialStates.forEach(({ name, initialValue }) => {
        const [state, setState] = useState(initialValue)
        stateSetters[name] = state
        stateSetters[`set${capitalizeFirstLetter(name)}`] = (value) => {
            setSearchParams((prev) => {
                const prevAsArray = Array.from(prev.entries())

                const isRemoved = !value.length
                if (isRemoved) {
                    return new URLSearchParams(prevAsArray.filter(([key]) => key !== name))
                }

                const isKeyNotExists = !prevAsArray.some(([key]) => key === name)
                if (isKeyNotExists) {
                    return new URLSearchParams([...prevAsArray, [name, value]])
                }

                return new URLSearchParams(
                    prevAsArray.map(([key, oldValue]) => (key === name ? [key, value] : [key, oldValue]))
                )
            })
        }

        useEffect(() => {
            const valParams = searchParams.get(name)
            if (valParams?.length) {
                setState(isArray(initialValue) ? valParams.split(',') : valParams)
                handleSearch()
            } else {
                setState(initialValue)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [initialValue, name, searchParams.toString()])
    })

    return stateSetters
}

function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
