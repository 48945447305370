import { useEffect, useState } from 'react'

export const useCustomPagination = (initialPerPage = 10) => {
    const [allEntries, setAllEntries] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(initialPerPage)
    const [totalPages, setTotalPages] = useState<number>(Math.ceil(allEntries / perPage))

    const handleChangePage = (number: number) => {
        setPage(number)
    }
    const handleChangePerPage = (number: number) => {
        setPerPage(number)
    }

    useEffect(() => {
        const calculatedTotalPages = Math.ceil(allEntries / perPage)
        setTotalPages(calculatedTotalPages)
        if (page > calculatedTotalPages) {
            if (calculatedTotalPages > 1) setPage(calculatedTotalPages - 1)
            else setPage(1)
        }
    }, [perPage, allEntries, page])

    return {
        handleChangePage,
        handleChangePerPage,
        setAllEntries,
        setPerPage,
        allEntries,
        page,
        perPage,
        totalPages,
    }
}
