import { styled, Tooltip, TooltipProps } from '@mui/material'
import React from 'react'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: 'rgba(30,30,30,.8)',
        color: 'white',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
        fontSize: '12px',
        padding: '8px',
        borderRadius: '4px',
    },
    [`& .MuiTooltip-arrow`]: {
        color: 'rgba(30, 30, 30, 0.8)',
    },
}))

export const CustomTooltip: React.FC<TooltipProps> = ({ children, title, ...props }) => {
    return (
        <StyledTooltip title={title} arrow {...props}>
            {children}
        </StyledTooltip>
    )
}
