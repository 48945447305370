import { Box, Button, FormControl, ListItemText, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import SliderFilter from './filters/SliderFilter'
import { StyledDatePicker } from 'src/components/general/StyledDatePicker'
import { StyledSelectWithBorder } from 'src/components/general/StyledSelect'
import { StyledCheckBox, StyledMenuItem } from '../generalComponents/TableComponents'
import { useState } from 'react'
import { defaultSliderValue } from 'src/hooks/useEventAnalytics'
import { platformSelectArray as platformSelectArrayDefault } from 'src/common/constants/overview'

const platformSelectArray = [...platformSelectArrayDefault, { name: 'stubhub', tag: 'Stubhub W/O Primary' }]

export function AnalyticsFilters({
    dataCategories,

    startDate,
    endDate,
    fromPresaleDate,
    toPresaleDate,
    handleSetStartDate,
    handleSetEndDate,
    handleSetFromPresaleDate,
    handleSetToPresaleDate,

    category,
    setCategory,
    setSwitcher,
    handleDisableAll,

    handleChangeSlider,
    marketplace,
    setMarketplace,
    sliderValue,
    excluded,
    setExcluded,
    seatGroups,
    setSeatGroups,
    seatGroupList,
    seatType,
    setSeatType,

    saleType,
    setSaleType,
}: {
    dataCategories

    startDate: Date
    endDate: Date
    fromPresaleDate: Date
    toPresaleDate: Date

    handleSetEndDate: (value, context) => void
    handleSetStartDate: (value, context) => void
    handleSetFromPresaleDate: (value, context) => void
    handleSetToPresaleDate: (value, context) => void

    setCategory
    setSwitcher
    category: string[]
    handleDisableAll: () => void

    handleChangeSlider: (event: Event, newValue: number | number[], name: string) => void
    marketplace
    setMarketplace
    sliderValue
    excluded
    setExcluded
    seatGroups
    setSeatGroups
    seatGroupList
    seatType
    setSeatType
    saleType
    setSaleType
}) {
    const [resetSwitcher, setResetSwitcher] = useState(false)
    //const [seatGroups, setSeatGroups] = useState([])
    const handleCategoryChange = (event: any) => {
        const selectedOptions = event.target.value
        if (selectedOptions.includes('All')) {
            setCategory([...(dataCategories?.data.data ? dataCategories?.data.data : selectedOptions)])
            return
        }
        if (selectedOptions.includes('Unselect_All')) {
            setCategory([])
            return
        }
        setCategory(selectedOptions)
    }
    //console.log("seatGroups:", seatGroups)
    //console.log("seatGroupList:", seatGroupList)
    console.log('seatType:', seatType)
    //console.log("marketplace:", marketplace)

    return (
        <>
            <Box
                sx={{
                    mt: '20px',
                    display: 'flex',
                    alignItems: 'flex-end',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: '24px',
                        gap: '8px',
                    }}
                >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>Event Date From</Typography>
                    <StyledDatePicker maxDate={endDate} date={startDate} handleSetDate={handleSetStartDate} />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: '32px',
                        gap: '8px',
                    }}
                >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>Event Date To</Typography>
                    <StyledDatePicker minDate={startDate} date={endDate} handleSetDate={handleSetEndDate} />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: '32px',
                        gap: '8px',
                    }}
                >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>From First Sale Date</Typography>
                    <StyledDatePicker
                        maxDate={toPresaleDate}
                        date={fromPresaleDate}
                        handleSetDate={handleSetFromPresaleDate}
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: '32px',
                        gap: '8px',
                    }}
                >
                    <Typography sx={{ fontWeight: '500', fontSize: '16px' }}>To First Sale Date</Typography>
                    <StyledDatePicker
                        minDate={fromPresaleDate}
                        date={toPresaleDate}
                        handleSetDate={handleSetToPresaleDate}
                    />
                </Box>
                <FormControl sx={{ mr: '12px', minWidth: '141px', borderRadius: '8px' }}>
                    <Typography sx={{ fontWeight: '500', mb: '5px', fontSize: '16px' }}>Category</Typography>
                    <StyledSelectWithBorder
                        sx={{ height: '44px', maxWidth: '139px' }}
                        multiple
                        value={category}
                        onChange={handleCategoryChange}
                        renderValue={(selected: Array<any>) => selected?.join(', ').replace('_', ' ')}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {category.length == dataCategories?.data.data.length ? (
                            <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                <StyledCheckBox
                                    sx={{ color: '#000000' }}
                                    checked={category.length == dataCategories?.data.data.length}
                                    checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                />
                                <ListItemText primary={'Unselect All'} />
                            </StyledMenuItem>
                        ) : (
                            <StyledMenuItem key={'All'} value={'All'}>
                                <StyledCheckBox
                                    sx={{ color: '#000000' }}
                                    checked={category.length == dataCategories?.data.data.length}
                                    checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                />
                                <ListItemText primary={'All'} />
                            </StyledMenuItem>
                        )}
                        {dataCategories?.data.data.map((cat) => (
                            <StyledMenuItem key={cat} value={cat}>
                                <StyledCheckBox
                                    sx={{ color: '#000000' }}
                                    checked={category.indexOf(cat) > -1}
                                    checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                />
                                <Typography sx={{ fontSize: '15px' }}>{cat}</Typography>
                            </StyledMenuItem>
                        ))}
                    </StyledSelectWithBorder>
                </FormControl>
                <FormControl sx={{ minWidth: '141px', borderRadius: '8px', mr: '12px' }}>
                    <Typography sx={{ fontSize: '16px', mb: '5px', fontWeight: '500' }}> Exclude</Typography>
                    <StyledSelectWithBorder
                        label="Exclude"
                        multiple
                        value={excluded}
                        onChange={(event) => {
                            const selectedOptions = event.target.value as string[]
                            if (selectedOptions.includes('off')) {
                                setExcluded([])
                                return
                            }
                            setExcluded(selectedOptions)
                        }}
                        placeholder="Off"
                        renderValue={(selected: Array<any>) =>
                            selected.map((elem) => elem[0].toUpperCase() + elem.slice(1)).join(', ')
                        }
                    >
                        <StyledMenuItem value={'off'}>
                            <StyledCheckBox
                                sx={{ opacity: 0 }}
                                checkedIcon={<CheckBoxIcon sx={{ color: 'white' }} />}
                            />
                            <ListItemText primary={'Off'} />
                        </StyledMenuItem>
                        <StyledMenuItem value={'ended'}>
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={excluded.includes('ended')}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'Ended'} />
                        </StyledMenuItem>
                        <StyledMenuItem value={'cancelled'}>
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={excluded.includes('cancelled')}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'Cancelled'} />
                        </StyledMenuItem>
                        <StyledMenuItem value={'resale'}>
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={excluded.includes('resale')}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'Resale'} />
                        </StyledMenuItem>
                    </StyledSelectWithBorder>
                </FormControl>
                <FormControl sx={{ minWidth: '141px', borderRadius: '8px', mr: '12px' }}>
                    <Typography sx={{ fontSize: '16px', mb: '5px', fontWeight: '500' }}> Marketplace</Typography>
                    <StyledSelectWithBorder
                        label="Market"
                        multiple
                        value={marketplace}
                        onChange={(event) => {
                            const selectedOptions = event.target.value as string[]
                            if (selectedOptions.includes('All')) {
                                setMarketplace(platformSelectArray.map((elem) => elem.name))
                                return
                            }
                            if (selectedOptions.includes('Unselect_All')) {
                                setMarketplace([])
                                return
                            }
                            setMarketplace(selectedOptions)
                        }}
                        renderValue={(selected: Array<any>) =>
                            selected.map((elem) => elem[0].toUpperCase() + elem.slice(1)).join(', ')
                        }
                    >
                        {marketplace.length === platformSelectArray.length ? (
                            <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                <StyledCheckBox
                                    sx={{ color: '#000000' }}
                                    checked={marketplace.length === platformSelectArray.length}
                                    checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                />
                                <ListItemText primary={'Unselect All'} />
                            </StyledMenuItem>
                        ) : (
                            <StyledMenuItem key={'All'} value={'All'}>
                                <StyledCheckBox
                                    sx={{ color: '#000000' }}
                                    checked={marketplace.length === platformSelectArray.length}
                                    checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                />
                                <ListItemText primary={'All'} />
                            </StyledMenuItem>
                        )}
                        {platformSelectArray.map(({ name, tag }) => (
                            <StyledMenuItem key={name} value={name}>
                                <StyledCheckBox
                                    sx={{ color: '#000000' }}
                                    checked={marketplace.indexOf(name) > -1}
                                    checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                />
                                <ListItemText primary={tag} />
                            </StyledMenuItem>
                        ))}
                    </StyledSelectWithBorder>
                </FormControl>

                <Button
                    sx={{
                        backgroundColor: '#F7F6F4',
                        minWidth: '81px',
                        height: '44px',
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: '#F7F6F4',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={() => {
                        handleDisableAll()
                        setSwitcher((e) => !e)
                        setResetSwitcher((e) => !e)
                    }}
                    variant="contained"
                >
                    <Typography noWrap={true} sx={{ fontWeight: '500' }}>
                        Reset
                    </Typography>
                </Button>
                <Button
                    sx={{
                        backgroundColor: '#204391',

                        minWidth: '81px',
                        height: '44px',
                        borderRadius: '8px',
                        ml: '10px',
                        '&:hover': {
                            backgroundColor: '#204391',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={() => {
                        setSwitcher((e) => !e)
                    }}
                    variant="contained"
                >
                    <Typography noWrap={true} sx={{ color: '#FFFFFF', fontWeight: '500' }}>
                        Apply
                    </Typography>
                </Button>
            </Box>
            <Box
                sx={{
                    mt: '10px',
                    // FIXME:
                    // display: 'flex',
                    display: 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <FormControl sx={{ minWidth: '181px', borderRadius: '8px', mr: '12px' }}>
                    <Typography sx={{ fontSize: '16px', mb: '5px', fontWeight: '500' }}>Seat Group</Typography>
                    <StyledSelectWithBorder
                        label="Seat Group"
                        value={seatGroups} // Single value for the selected seat group
                        onChange={(event) => {
                            const selectedOption = event.target.value as string
                            setSeatGroups(selectedOption) // Set the selected option to seatGroups
                            setSeatType([]) // Clear seatType on seat group change
                        }}
                        renderValue={(selected: string) =>
                            selected ? selected[0].toUpperCase() + selected.slice(1) : ''
                        }
                    >
                        {/* Check if seatGroupList is empty and display a message if it is */}
                        {seatGroupList.length === 0 ? (
                            <StyledMenuItem disabled>
                                <ListItemText primary="No seat groups found" />
                            </StyledMenuItem>
                        ) : (
                            seatGroupList.map(({ name }) => (
                                <StyledMenuItem key={name} value={name}>
                                    <ListItemText primary={name} />
                                </StyledMenuItem>
                            ))
                        )}
                    </StyledSelectWithBorder>
                </FormControl>

                {seatGroups !== '' ? (
                    <FormControl sx={{ minWidth: '141px', borderRadius: '8px', mr: '12px' }}>
                        <Typography sx={{ fontSize: '16px', mb: '5px', fontWeight: '500' }}> Seat Type</Typography>
                        <StyledSelectWithBorder
                            label="Seat Type"
                            multiple
                            value={seatType}
                            onChange={(event) => {
                                const selectedOptions = event.target.value as string[]
                                if (selectedOptions.includes('All')) {
                                    setSeatType(seatGroupList.find((elem) => elem.name === seatGroups)?.seatType || [])
                                    return
                                }
                                if (selectedOptions.includes('Unselect_All')) {
                                    setSeatType([])
                                    return
                                }
                                //setSeatType((prevSeatType) => [...new Set([...prevSeatType, ...selectedOptions])]);
                                setSeatType(selectedOptions)
                            }}
                            renderValue={(selected: Array<any>) =>
                                selected.map((elem) => elem[0].toUpperCase() + elem.slice(1)).join(', ')
                            }
                        >
                            {seatType.length ===
                            seatGroupList.find((elem) => elem.name === seatGroups)?.seatType.length ? (
                                <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                    <StyledCheckBox
                                        sx={{ color: '#000000' }}
                                        checked={
                                            seatType.length ===
                                            seatGroupList.find((elem) => elem.name === seatGroups)?.seatType.length
                                        }
                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                    />
                                    <ListItemText primary={'Unselect All'} />
                                </StyledMenuItem>
                            ) : (
                                <StyledMenuItem key={'All'} value={'All'}>
                                    <StyledCheckBox
                                        sx={{ color: '#000000' }}
                                        checked={
                                            seatType.length ===
                                            seatGroupList.find((elem) => elem.name === seatGroups)?.seatType.length
                                        }
                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                    />
                                    <ListItemText primary={'All'} />
                                </StyledMenuItem>
                            )}
                            {seatGroupList
                                .find((elem) => elem.name === seatGroups)
                                ?.seatType.map((elem) => (
                                    <StyledMenuItem key={elem} value={elem}>
                                        <StyledCheckBox
                                            sx={{ color: '#000000' }}
                                            checked={seatType.indexOf(elem) > -1}
                                            checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                        />
                                        <ListItemText primary={elem} />
                                    </StyledMenuItem>
                                ))}
                        </StyledSelectWithBorder>
                    </FormControl>
                ) : null}

                <FormControl sx={{ minWidth: '181px', borderRadius: '8px', mr: '12px' }}>
                    <Typography sx={{ fontSize: '16px', mb: '5px', fontWeight: '500' }}>Sale Status</Typography>
                    <StyledSelectWithBorder
                        label="Seat Type"
                        value={saleType}
                        onChange={(event) => {
                            const selectedOption = event.target.value as string
                            setSaleType(selectedOption)
                        }}
                        renderValue={(selected: string) => {
                            if (selected === '') {
                                return ''
                            }
                            let formatted = selected.replace('_', ' ')
                            return formatted[0].toUpperCase() + formatted.slice(1)
                        }}
                    >
                        <StyledMenuItem value={''}>
                            <ListItemText primary={'Off'} />
                        </StyledMenuItem>
                        <StyledMenuItem value={'first_presale'}>
                            <ListItemText primary={'First sale'} />
                        </StyledMenuItem>
                    </StyledSelectWithBorder>
                </FormControl>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: '1fr 1fr',
                    gap: '20px',
                    mt: '34px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '109px',
                        paddingBottom: '20px',
                    }}
                >
                    <SliderFilter
                        switcher={resetSwitcher}
                        defaultSliderValue={defaultSliderValue}
                        label={'Spotify Rank'}
                        id={'spotifyRankValue'}
                        colordefault={'#49C993'}
                        handleChangeGlobal={handleChangeSlider}
                        max={100}
                        disableSwap
                        value={sliderValue.spotifyRankValue}
                    ></SliderFilter>
                    <SliderFilter
                        switcher={resetSwitcher}
                        defaultSliderValue={defaultSliderValue}
                        label={'Capacity'}
                        id={'capacityValue'}
                        colordefault="#204391"
                        step={100}
                        handleChangeGlobal={handleChangeSlider}
                        value={sliderValue.capacityValue}
                        max={100000}
                        disableSwap
                    ></SliderFilter>
                    <SliderFilter
                        value={sliderValue.priceValue}
                        switcher={resetSwitcher}
                        defaultSliderValue={defaultSliderValue}
                        label={'Get in Price'}
                        id={'priceValue'}
                        colordefault="#4F7DCB"
                        handleChangeGlobal={handleChangeSlider}
                        scalingStart={100}
                        scalingStrength={50}
                        max={198}
                        disableSwap
                        note='Note: Adjusting the “get in price" filter will exclude all events for which we do not have get in price data'
                    ></SliderFilter>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '109px',
                        paddingBottom: '20px',
                        position: 'relative',
                    }}
                >
                    <SliderFilter
                        switcher={resetSwitcher}
                        defaultSliderValue={defaultSliderValue}
                        label={'Primary Availability'}
                        id={'primaryValue'}
                        scalingStart={100}
                        scalingStrength={100}
                        colordefault="#F2994A"
                        handleChangeGlobal={handleChangeSlider}
                        max={399}
                        note="Note: Adjusting the “primary availability” filter will exclude all events without primary data and affect secondary events that match those without primary data. "
                        disableSwap
                        value={sliderValue.primaryValue}
                    ></SliderFilter>

                    <SliderFilter
                        switcher={resetSwitcher}
                        defaultSliderValue={defaultSliderValue}
                        label={'Secondary Availability'}
                        id={'secondaryValue'}
                        colordefault="#F2994A"
                        scalingStart={100}
                        scalingStrength={100}
                        handleChangeGlobal={handleChangeSlider}
                        max={399}
                        disableSwap
                        value={sliderValue.secondaryValue}
                        note="Note: Adjusting the “secondary availability” filter will exclude all primary events that we did not manage to match"
                    ></SliderFilter>
                    <SliderFilter
                        switcher={resetSwitcher}
                        defaultSliderValue={defaultSliderValue}
                        label={'Primary Percentage Sold'}
                        id={'sellouts'}
                        colordefault="#4F7DCB"
                        handleChangeGlobal={handleChangeSlider}
                        valueLabelFormat={(value, index) => value + '%'}
                        max={100}
                        disableSwap
                        value={sliderValue.sellouts}
                    ></SliderFilter>
                </Box>
            </Box>
        </>
    )
}
