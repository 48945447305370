import { isAfter, isBefore, isDate, isSameDay, isSameHour, isToday } from 'date-fns'
import dayjs from 'dayjs'
import { ISaleStatus } from 'src/types/local/customTableTypes'
import { Datum, IPresale } from 'src/types/local/upSalesResp'

export const convertDate = (str: string, inputTimeZone?: string) => {
    if (str === undefined) return '0'
    else {
        const originalDateTime: Date = new Date(str)

        let formatter
        if (inputTimeZone === null) {
            formatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
        } else {
            formatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZone: inputTimeZone,
            })
        }

        return `${formatter.format(originalDateTime)}`
    }
}

export function findMaxByStartDateTime(objects: Datum, currentDate?: Date): string | null {
    if (objects?.presales === null || !Array.isArray(objects.presales) ? true : objects?.presales?.length === 0) {
        return objects?.saleStartDate
    }

    let maxObject = null
    let maxDateTime: Date | null = null
    const now = currentDate ?? new Date()

    for (const obj of objects?.presales) {
        const currentDateTime: Date = new Date(obj?.startDateTime)

        const isSameDay =
            currentDateTime.getFullYear() === now.getFullYear() &&
            currentDateTime.getMonth() === now.getMonth() &&
            currentDateTime.getDate() === now.getDate()

        if (!currentDate || isSameDay) {
            if (maxDateTime === null || currentDateTime > maxDateTime) {
                maxDateTime = currentDateTime
                maxObject = obj
            }
        }
    }

    return maxObject?.startDateTime ?? objects?.saleStartDate
}

export function findLatestPresaleStartV2(presales: IPresale[]): string | null {
    if (presales === null || !Array.isArray(presales) || presales?.length === 0) {
        return null
    }

    let maxObject = null
    let maxDateTime: Date | null = null

    for (const obj of presales) {
        const currentDateTime: Date = new Date(obj?.startDateTime)

        if (maxDateTime === null || currentDateTime > maxDateTime) {
            maxDateTime = currentDateTime
            maxObject = obj
        }
    }

    return maxObject?.startDateTime
}

export function findMinByStartDateTime(objects: Datum): string | null {
    if (objects?.presales === null || !Array.isArray(objects.presales) || objects?.presales?.length === 0) {
        return null
    }

    let minObject = null
    let minDateTime: Date | null = null

    for (const obj of objects.presales) {
        const currentDateTime: Date = new Date(obj?.startDateTime)

        if (minDateTime === null || currentDateTime < minDateTime) {
            minDateTime = currentDateTime
            minObject = obj
        }
    }

    return minObject?.startDateTime
}

export function findEarliestPresaleEndV2(presales: IPresale[]): string | null {
    if (!Array.isArray(presales) || presales.length === 0) {
        return null
    }

    let minObject: IPresale | null = null
    let minDateTime: Date | null = null

    for (const obj of presales) {
        const currentDateTime: Date = new Date(obj?.startDateTime)

        if (minDateTime === null || currentDateTime < minDateTime) {
            minDateTime = currentDateTime
            minObject = obj
        }
    }

    return minObject?.startDateTime ?? null
}

export function findLatestPresaleEndDate(presales: IPresale[]): string | null {
    if (presales === null || !Array.isArray(presales) || presales?.length === 0) {
        return null
    }

    let maxDateTime: Date | null = null

    for (const obj of presales) {
        const currentDateTime: Date = new Date(obj?.endDateTime)

        if (maxDateTime === null || currentDateTime > maxDateTime) {
            maxDateTime = currentDateTime
        }
    }

    return maxDateTime ? maxDateTime.toISOString() : null
}

export function findEarliestSaleEndDate(presales: IPresale[]): string | null {
    if (!Array.isArray(presales) || presales.length === 0) {
        return null
    }

    let minDateTime: Date | null = null

    for (const obj of presales) {
        const currentDateTime: Date = new Date(obj?.endDateTime)

        if (minDateTime === null || currentDateTime < minDateTime) {
            minDateTime = currentDateTime
        }
    }

    return minDateTime ? minDateTime.toISOString() : null
}

export const FormatDate = (str: string, inputTimeZone?: string | null) => {
    const originalDateTime = new Date(str)
    let formatedDate

    if (inputTimeZone === null) {
        formatedDate = originalDateTime.toLocaleString('en-US')
    } else {
        formatedDate = originalDateTime.toLocaleString('en-US', {
            timeZone: inputTimeZone,
        })
    }

    const NewformatedDate = formatedDate
        .replace('T', ' ')
        .replace('/', '-')
        .replace('/', '-')
        .replace(',', '')
        .replace('Z', '')
        .slice(0, -6)
    return NewformatedDate
}

export const checkingDate = (presale: string, salestart: string, valueDate: Date) => {
    const presaling = new Date(presale)
    const saleStart = new Date(salestart)

    if (!isDate(valueDate)) {
        valueDate = new Date(valueDate)
    }

    return (
        (presaling.getMonth() < saleStart.getMonth() ||
            (presaling.getMonth() === saleStart.getMonth() && presaling.getDate() <= saleStart.getDate())) &&
        (presaling.getMonth() > valueDate.getMonth() ||
            (presaling.getMonth() === valueDate.getMonth() && presaling.getDate() >= valueDate.getDate()))
    )
}

export const getSaleStatus = ({
    presales,
    saleStartDate,
    dateForCompare,
    timezone,
}: {
    presales: IPresale[]
    saleStartDate: string
    saleEndDate: string
    dateForCompare: Date
    timezone: string
}): ISaleStatus => {
    let status: ISaleStatus['status'] = 'unknown'
    let isFirstSale = false

    if ((!presales || presales.length === 0) && (!saleStartDate || saleStartDate.length === 0)) {
        return { status, isFirstSale }
    }

    const saleStartAsDate = convertToTimezone(new Date(saleStartDate), timezone)

    const earliestPresale = findEarliestPresaleEndV2(presales)
    const earliestPresaleStartDate = earliestPresale ? convertToTimezone(new Date(earliestPresale), timezone) : null

    const dateForCompareWithTimezone = convertToTimezone(dateForCompare, timezone)

    const isSaleStarted = isBefore(saleStartAsDate, dateForCompareWithTimezone)
    const isPresaleStarted = earliestPresaleStartDate && isBefore(earliestPresaleStartDate, dateForCompareWithTimezone)

    if (
        !isSaleStarted &&
        !isPresaleStarted &&
        earliestPresaleStartDate &&
        !isSameHour(earliestPresaleStartDate, saleStartAsDate)
    ) {
        status = 'upcoming presale'
        isFirstSale = isSameDay(earliestPresaleStartDate, dateForCompareWithTimezone)
    } else if (!isSaleStarted && isPresaleStarted) {
        status = 'presale'
        isFirstSale = isSameDay(earliestPresaleStartDate, dateForCompareWithTimezone)
    } else if (!isSaleStarted) {
        status = 'upcoming sale'
        isFirstSale = isSameDay(saleStartAsDate, dateForCompareWithTimezone)
    } else {
        status = 'on sale'
    }

    return { status, isFirstSale }
}

export const formatDate = (date: Date, format: string) => {
    return dayjs(date).format(format)
}

export const applyTimeZone = (str: string, inputTimeZone: string) => {
    if (!str || !inputTimeZone) {
        return new Date(str)
    }
    const originalDateTime = new Date(str)
    return originalDateTime.toLocaleString('en-US', {
        timeZone: inputTimeZone,
    })
}

const convertToTimezone = (date: Date, timezone: string): Date => {
    const localeString = date.toLocaleString('en-US', { timeZone: timezone })
    return new Date(localeString)
}
