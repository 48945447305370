import { TUserAdminPermissions } from 'src/types/local/adminPermissions'

export const USER_PERMISSIONS: {
    [key in TUserAdminPermissions]: key
} = {
    MANAGE_ADMIN_DASHBOARD: 'MANAGE_ADMIN_DASHBOARD',
    MANAGE_USER_TYPE: 'MANAGE_USER_TYPE',
    MANAGE_USER_PERMISSIONS: 'MANAGE_USER_PERMISSIONS',

    MANAGE_DELETE_EVENT: 'MANAGE_DELETE_EVENT',
    MANAGE_EDIT_EVENT: 'MANAGE_EDIT_EVENT',

    MANAGE_CAPACITY: 'MANAGE_CAPACITY',
    MANAGE_PRESALE_CODES: 'MANAGE_PRESALE_CODES',
    MANAGE_ADD_MATCH_URL: 'MANAGE_ADD_MATCH_URL',
    MANAGE_ARTIST_PRESALE_CODES: 'MANAGE_ARTIST_PRESALE_CODES',

    MANAGE_WATCHLIST: 'MANAGE_WATCHLIST',

    MANAGE_ADD_UPCOMING_SALE: 'MANAGE_ADD_UPCOMING_SALE',

    MANAGE_VENUE_MATCH: 'MANAGE_VENUE_MATCH',

    MANAGE_SUBS: 'MANAGE_SUBS',
    MANAGE_TIERS: 'MANAGE_TIERS',
}
