import { Box, Typography } from '@mui/material'
import { ISaleStatus } from 'src/types/local/customTableTypes'

export const SaleStatus = ({
    saleStatus,
    isDisableShowUnknown = false,
    isDisableShowFirstSale = false,
    isDisableShowOnSale = false,
    isSmall = false,
}: {
    saleStatus: ISaleStatus
    isDisableShowUnknown?: boolean
    isDisableShowFirstSale?: boolean
    isDisableShowOnSale?: boolean
    isSmall?: boolean
}) => {
    if (
        !saleStatus ||
        (isDisableShowUnknown && saleStatus.status === 'unknown') ||
        (isDisableShowOnSale && saleStatus.status === 'on sale')
    ) {
        return null
    }

    let textColor = 'grey'
    let bgColor = 'white'
    let borderColor = 'grey'

    switch (saleStatus.status) {
        case 'upcoming presale':
        case 'upcoming sale':
            if (saleStatus.isFirstSale) {
                textColor = '#00b800'
                bgColor = '#E5FFE5'
                borderColor = '#00FF00'
            } else {
                textColor = '#FFA500'
                bgColor = '#FFF5E5'
                borderColor = '#FFA500'
            }
            break
        case 'presale':
        case 'on sale':
            if (saleStatus.isFirstSale) {
                textColor = '#00b800'
                bgColor = '#E5FFE5'
                borderColor = '#00FF00'
            } else {
                textColor = '#0000FF'
                bgColor = '#E5E5FF'
                borderColor = '#0000FF'
            }
            break
    }

    return (
        <Box
            sx={{
                bgcolor: bgColor,
                color: textColor,
                width: isSmall ? 'auto' : '100%',
                textAlign: 'center',
                borderRadius: '10px',
                padding: isSmall ? '0px 5px' : '4px 8px',
                fontSize: isSmall ? '12px' : '14px',
                border: isSmall ? 'none' : `solid 1px ${borderColor}`,
                ...(isSmall && { margin: '0px 3px' }),
            }}
        >
            <Typography sx={{ fontSize: isSmall ? '12px' : 'inherit' }}>
                {saleStatus.status
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
            </Typography>
            {!isDisableShowFirstSale && saleStatus.isFirstSale && (
                <Typography sx={{ fontWeight: 'bold', fontSize: isSmall ? '12px' : 'inherit' }}>First Sale!</Typography>
            )}
        </Box>
    )
}
